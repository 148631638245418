<template>
    <BaseLayout>
        <div class="row body-login">            
            <div class="col-lg-6 order-lg-2 col-xs-12 order-xs-1">
                <div class="form-login">
                    <div v-if="showForgotPwd">
                        <h1 style="color:#000;">Atur Ulang Kata Sandi</h1>
                        <Alert v-show="alert.messages!=null" :type="alert.type" :collection="alert.messages"></Alert>
                        <div class="field-wrap">
                            <label class="label-login"> Masukan Email Anda <span class="req">*</span>
                            </label>
                            <input id="email" type="email" class="ol-input form-control" name="email" v-model="input.email" required autocomplete="email" autofocus>
                        </div>
                        <div class="">
                            <button type="button" @click="sendLink()" class="ol-btn button-block">Kirim</button>
                        </div>
                    </div>
                    <div v-if="showChangePwd">
                        <h1 style="color:#000;">Ubah Kata Sandi</h1>
                        <Alert v-show="alert.messages!=null" :type="alert.type" :collection="alert.messages"></Alert>
                        <div class="field-wrap">
                            <label class="label-login"> Kata Sandi Baru <span class="req">*</span>
                            </label>
                            <Password v-model="pwd.new_password" :badge="false" :toggle="true" @score="showScore"/>
                        </div>
                        <div class="field-wrap">
                            <label class="label-login"> Konfirmasi Kata Sandi <span class="req">*</span>
                            </label>
                            <Password v-model="pwd.confirm_password" :toggle="true" :showStrengthMeter="false" :badge="false" />
                        </div>
                        <div class="">
                            <button type="button" @click="save()" class="ol-btn button-block">Simpah</button>
                        </div>
                    </div>
                    <br>
                    <router-link to="/login" class="ol-btn ol-btn--secondary button-block">Kembali ke halaman login</router-link>
                </div>
            </div>
            <div class="col-lg-6 order-lg-1 col-xs-12 order-xs-2" style="text-align: center;margin:auto;">
                <img src="https://asset.morefurniture.id/MORE/asset/image-login.png" width="65%">
            </div>
        </div>
    </BaseLayout>
</template>


<script>
import BaseLayout from '@/components/landing/BaseLayout.vue';
import globalUrl from '../../util/globallUrl'
import Password from 'vue-password-strength-meter'
const URL = globalUrl.WS_AUTH;
const MAIL = globalUrl.WS_MAIL;


export default {
	name: "ForgotPassword",
    components:{
        Password,
        BaseLayout,
    },
    data(){
        return {
            input:{
                email:null,
            },
            pwd:{
                email:null,
                new_password: null,
                confirm_password: null,
            },
            alert:{
                messages:{email:["Kami akan mengirimkan link ke email Anda"]},
                message:null,
                type:"info",
            },
            token:null,
            showForgotPwd:true,
            showChangePwd:false,
            score:0,
            loading:false,

        }
    },
    computed:{
    },
    methods:{
        resetForm(){
            this.pwd.new_password = null;
            this.pwd.confirm_password = null;
        },
        showScore(score){
            this.score = score;
        },
        async sendLink(){
            if(!this.input.email){
                this.alert.messages={email:["Email wajib dilengkapi!"]};
                this.alert.type="danger";
            }else{
                this.loading = true;
                await MAIL.REQUEST_SET_PWD(this.input).then(response => {
                    let result = response.data;
                    this.alert.messages={email:[result.data.message]};
                    this.alert.type="success";
                    this.input.email = null;
                    this.loading = false;

                }).catch(error => {
                    let result = error.response.data;
                    // console.log("sendLink", result);
                    this.alert.messages=result.data;
                    this.alert.type="danger";
                    this.loading = false;
                });
            }
        },
        async validateToken(){
            this.loading = true;
            let result=null;
            await MAIL.VALID_MAIL_PWD(this.token).then(response => {
                result = response.data;
                this.pwd.email = result.data.email;
                $("#btn-fpwd").prop("disabled",false);
                this.loading = false;
            }).catch(error => {
                let resp = error.response.data;
                // console.log("validateToken", resp);
                this.alert.messages= _.isObject(resp.data) ? resp.data: {pwd:["Link yang Anda gunakan tidak valid."]};
                this.alert.type="danger";
                this.loading = false;
                $("#btn-fpwd").prop("disabled",true);
            });
            return result;
        },
        async save(){
            if(!this.pwd.new_password){
                this.alert.messages={pwd:["Kata sandi baru wajib dilengkapi."]};
                this.alert.type="danger";
            }else if(!this.pwd.confirm_password){
                this.alert.messages={pwd:["Konfirmasi Kata sandi wajib dilengkapi."]};
                this.alert.type="danger";
            }else{
                if(this.score >= 1){
                    if(this.pwd.new_password === this.pwd.confirm_password){
                        if(this.pwd.email){
                            this.loading = true;
                            await URL.RESET_PASSWORD(this.pwd).then(response => {
                                this.$router.replace("/login");
                            }).catch(error => {
                                let result = error.response.data;
                                // console.log("save", result);
                                this.alert.messages= _.isObject(result.data) ? result.data: {pwd:["Gagal memperbarui Kata sandi"]};
                                this.alert.type="danger";
                                this.loading = false;
                            });
                        }else{
                            this.alert.messages={pwd:["Link anda tidak valid"]};
                            this.alert.type="danger";
                        }
                    }else{
                        this.alert.messages={pwd:["Gagal Konfirmasi kata sandi baru, periksa kembali."]};
                        this.alert.type="danger";
                    }
                }else{
                    this.alert.messages={pwd:["Kekuatan kata sandi minimal medium (memenuhi 2 bar)"]};
                    this.alert.type="danger";
                }
            }
        },
        
    },
    async mounted(){
        if(this.$route.params.token) {
            this.token=this.$route.params.token;
            this.alert.messages=null;
            this.showForgotPwd = false;
            this.showChangePwd = true;
            await this.validateToken(); 
        }
    }
};
</script>

<style>
@import '../../assets/css/login.css';
</style>