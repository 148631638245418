<template>
    <BaseLayout>
        <h1 style="display:none">Toko Furniture Rumah Tangga Online | MORE Furniture</h1>
        <div class="break-24 retail-dekstop"></div>
        <section class="ol-home-menu">
            <BannerBottomLoading v-if="loading" />
            <BannerTop v-else :banners="bannerTop" :clickable="true" />
        </section>
        <FlashsaleGroup id="flashsale" />

        <!-- ol-home-category -->
        <Categories :categories="categoriesGroupJson" />
        <!-- /ol-home-category -->
        
        <section class="ol-home-type-product">
            <div class="ol-wrapper ol-wrapper--large">
                <div class="title-div-content">
                    <h2 class="heading-content">Belanja Sesuai Budget</h2>
                    <span class="sub-heading-content">Makin Hemat, Beli Furnitur Impian Sesuai Budget</span>
                </div>
                <div class="wrapper-event ">
                    <div >
                        <a href="/products/q=&price_min=0&price_max=500000">
                            <img  :src="require('@/assets/images/benefit/_500.png')" style="width:100%;border-radius: 8px;"/>
                        </a>
                    </div>
                    <div >
                        <a href="/products/q=&price_min=500000&price_max=1000000">
                            <img  :src="require('@/assets/images/benefit/500-1000.png')" style="width:100%;border-radius: 8px;"/>
                        </a>
                    </div>
                    <div >
                        <a href="/products/q=&price_min=1000000&price_max=2000000">
                            <img  :src="require('@/assets/images/benefit/1000-2000.png')" style="width:100%;border-radius: 8px;"/>
                        </a>
                    </div>
                    <div >
                        <a href="/products/q=&price_min=2000000&price_max=10000000">
                            <img  :src="require('@/assets/images/benefit/_2000.png')" style="width:100%;border-radius: 8px;"/>
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <ProductListGroup :groups="productGroups" />

        <!-- <Endorsement /> -->

        <section class="ol-home-type-product">
            <div class="ol-wrapper ol-wrapper--large">
                <div class="title-div-content">
                    <h2 class="heading-content">Produk Terakhir Dilihat</h2>
                    <span class="sub-heading-content"></span>
                </div>
                <div class="ol-product-content ol-product-related" >
                    <ProductLoader wrapclass="ol-product-content__center-list" :page="2" :qty="5" v-if="loadingLastSeen" />
                    <CarouselProduct :products="lastSeen" v-if="!loadingLastSeen" carouselKey="lastseen" />
                </div>
            </div>
        </section> 

        <div id="myModal-pop" class="modal-pop" v-if="modalShow" style="display:block;">
            <div id="modal_pop_content">
                <div>
                    <a :href="modalLink">
                        <img class="pop-img" :srcset="modalImg">
                    </a>
                    
                    <div class="closemod" >
                        <div style="position: relative;">
                            <img v-on:click="hideModal()" class="circlemod" src="@/assets/images/opening/circle.png">
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </BaseLayout>
</template>

<script>

import BaseLayout from '@/components/landing/BaseLayout.vue';
import Categories from "@/components/product/Categories.vue";
import BannerTop from "@/components/home/BannerTop.vue";
import BannerBottom from "@/components/home/BannerBottom.vue";
import Inspirasi from "@/components/home/InspirasiV2.vue";
import Endorsement from "@/components/home/Endorsement.vue";
import Carousel from "component-vue-efi/src/components/carousel/SlideCarousel.vue";
import CarouselLoader from "@/components/loading/CarouselLoader";
import ProductList from "@/components/product/ProductList.vue";
import ProductListGroup from "@/components/product/ProductListGroup.vue";
import ProductFlash from "@/components/product/ProductFlash.vue";
import FlashsaleGroup from '@/components/product/FlashsaleGroup';
import ProductLoader from '@/components/loading/product/Content.vue';
import ProductListBox from "@/components/product/ProductListBox.vue";
import TopProductLoader from "@/components/loading/product/Top";
import BannerBottomLoading from "@/components/loading/BannerBottomLoading";
import CarouselProduct from '@/components/home/CarouselProduct';
import Vue from 'vue';

import {mapActions,mapGetters} from "vuex";

export default {
    name: 'Home',
    metaInfo() {
        return{
            link: [
                {rel: 'canonical', href: window.location.origin+this.$route.fullPath}
            ]
        }
    },
    components:{
        ProductFlash,
        BaseLayout,
        Categories,
        ProductListBox,
        ProductListGroup,
        ProductList,
        ProductLoader,
        Carousel,
        CarouselLoader,
        TopProductLoader,
        BannerTop,
        BannerBottom,
        BannerBottomLoading,
        Inspirasi,
        Endorsement,
        FlashsaleGroup,
        CarouselProduct
    },
    data(){
        return {
            categories:[],
            modalShow: false,
            modalLink: null,
            modalImg:null,
            loading:false,
            loadingBottom:false,
            loadingTopProduct:false,
            loadingLastSeen:true,
        }
    },
    computed:{
        ...mapGetters("product/list",["products","topProducts","categoriesGroupJson"]),
        ...mapGetters("home",["bannerTop","popup","produtTerbaik","productGroups"]),
        ...mapGetters('auth', ['authenticated']),
        ...mapGetters('profile/product', ['lastSeen']),
    },
    methods:{
        ...mapActions('profile/product', ['saveProductHistory','fetchLastSeen']),
        ...mapActions("home",["fetchProductGroups", "getBanner","getPopup","getProdutTerbaik"]),
        ...mapActions("product/list",["fetchProducts","fetchTopProducts","getCategoriesGroupJson"]),

        setCategories(data){
            this.categories = data;
        },
        hideModal(){
            this.modalShow =false;
        },
        async getLastSeen(){
            this.loadingLastSeen = true;
            if(this.authenticated) await this.saveProductHistory();
            await this.fetchLastSeen();
            this.loadingLastSeen = false;
        },
    },
    async created(){
        this.$store.commit('transaction/checkout/setOrder', null);
    },
    updated(){
        // if(this.bannerTop) this.jqSlickTopBanner();
        // if(this.bannerBottom) this.jqSlickBotttomBanner();
    },
    async mounted(){
        this.loading = true;
        this.loadingBottom = true;
        this.loadingTopProduct = true;

        const params = new URLSearchParams(window.location.search);
        if(params.has('unAuth')) {
            this.$toast.open({ message: "Sesi telah habis, silahkan login kembali", type: "info", duration: 5000, });
        }

        await this.getPopup();

        await this.getBanner('1');
        this.loading = false;

        // await this.fetchTopProducts();
        await this.fetchProductGroups();
        // this.jqSlickFlashsale();

        await this.getCategoriesGroupJson();

        await this.getProdutTerbaik();
        // this.jqSlickFlashsale();

        await this.getLastSeen();
        this.loadingBottom = false;
        this.loadingTopProduct = false;

        
    },
    watch:{
        bannerBottom: function(value){
            this.loadingBottom =false;
        },
        popup:function(value){
            // console.log(value[0]);
            if(value.length > 0){
                var data = value[0];

                this.modalImg = data.image_url;
                this.modalLink = data.link;
                if(!this.modalShow) this.modalShow = true;

                // console.log('value', value);
                // var data = value[0];
                // const now = new Date();
                // const daystart = new Date(data.duration_start);
                // const dayend = new Date(data.duration_end);

                // if(now >= daystart && now <= dayend){
                //     this.modalImg = data.image_url;
                //     if(!this.modalShow) this.modalShow = true;
                // }else if(daystart > now){
                //     const diffTime = Math.abs(daystart - now);
                //     setTimeout(() => {
                //         this.modalImg = data.image_url;
                //         if(!this.modalShow) this.modalShow=true;
                //     }, diffTime);
                // }
            }
        },
        
	}
}
</script>

<style scoped lang="css">
.ol-home-menu{
    padding-top:24px;
}

.wrapper-event {
    display: grid;
    grid-template-columns: repeat(4, 1.2fr);
    /* grid-auto-rows: 45%; */
    column-gap: 8px;
    row-gap: 8px;
    text-align: center;
}

@media (max-width: 768px) {
    .wrapper-event {
        display: grid;
        grid-template-columns: repeat(4, 1.2fr);
        /* grid-auto-rows: 45%; */
        column-gap: 4px;
        row-gap: 4px;
        text-align: center;
    }
    .ol-home-menu{
        padding-top:0px;
    }
}

.ol-btn-wishlist{
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.retail-dekstop{
    display:block;
}

.retail-mobile{
    display:none;
}

@media(max-width:767px){
    .retail-dekstop{
        display:none;
    }

    .retail-mobile{
        display:block;
    }
}
</style>