<template>
  <div>
    <a href="javascript:void(0)" class="ol-totop" id="a-footer-to-top"><i class="ol-fa fas fa-arrow-up"></i></a>

    <!-- ol-footer -->
    <nav class="ol-footer-nav">
      <div class="ol-wrapper ol-wrapper--large">
        <div class="ol-footer-nav__link">
          <a :href="'/'">
            <i class="fa fa-home icon-mobile"></i>
            <span>Home</span>
          </a>
        </div>
        <div class="ol-footer-nav__link">
          <a :href="'/products'">
            <i class="fa fa-shopping-bag icon-mobile"></i>
            <span>Produk</span>
          </a>
        </div>
        <div class="ol-footer-nav__link">
          <!-- <div class="cart-mobile"> -->
          <a href="/cart">
            <i class="fa fa-shopping-cart icon-mobile "></i>
            <span>Keranjang</span>
          </a>
          <!-- </div> -->
        </div>
        <div class="ol-footer-nav__link">
          <a :href="'/promotion'">
            <i class="fa fa-percent icon-mobile"></i>
            <span>Promo</span>
          </a>
        </div>
        <!-- <div class="ol-footer-nav__link">
                <a :href="'/profile/wishlist'">
                    <i class="fa fa-heart icon-mobile"></i>
                    <span>Wishlist</span>
                </a>
            </div> -->
        <div class="ol-footer-nav__link">
          <a :href="authenticated ? '/plain-menu' : '/login'">
            <i class="fa fa-user icon-mobile"></i>
            <span>{{ authenticated ? 'Profil' : 'Login' }}</span>
          </a>
        </div>
      </div>
    </nav>

    <section v-show="loading" class="ol-product ol-product-detail" style="background:#FFFFFF;height:50px;">
      <SkeletonLoader />
    </section>

    <footer v-if="!loading" class="ol-footer" style="box-shadow: 0 1px 20px 0 var(--color-shadow,rgba(49,53,59,0.12));">
      <section class="ol-home-info" v-if="!loading">
        <div class="ol-wrapper ol-wrapper--large">
          <div class="row no-gutters">
            <div class="col-3">
              <div class="row no-gutters">
                <div class="col-lg-12"><img class="image-home-info"
                    :src="require('@/assets/images/benefit/ongkir.png')" /></div>
                <div class="col-lg-12">Gratis Ongkir</div>
              </div>
            </div>
            <div class="col-3">
              <div class="row no-gutters">
                <div class="col-lg-12"><img class="image-home-info"
                    :src="require('@/assets/images/benefit/garansi.png')" /></div>
                <div class="col-lg-12">Garansi Produk<br> 2x24 Jam</div>
              </div>
            </div>
            <div class="col-3">
              <div class="row no-gutters">
                <div class="col-lg-12"><img class="image-home-info" :src="require('@/assets/images/benefit/ori.png')" />
                </div>
                <div class="col-lg-12">Dijamin Ori</div>
              </div>
            </div>
            <div class="col-3">
              <div class="row no-gutters">
                <div class="col-lg-12"><img class="image-home-info"
                    :src="require('@/assets/images/benefit/pembayaran.png')" /></div>
                <div class="col-lg-12">Pembayaran Aman</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div v-if="!loading" class="ol-wrapper ol-wrapper--large" style="margin-bottom:25px;">

        <div class="row no-gutters">
          <div class="col-lg-4 col-md-12">
            <span class="title-h3-footer">Temukan Kami</span>
            <div class="break-1"></div>
            <div style="text-align:left;">
              <a v-bind:href="fb" target="_blank">
                <img class="image-footer" :srcset="icons('facebook-blue.webp')" />
              </a>
              <a v-bind:href="ig" target="_blank">
                <img class="image-footer" :srcset="icons('instagram-blue.webp')" />
              </a>
              <a v-bind:href="tk" target="_blank">
                <img class="image-footer" :srcset="icons('tiktok-blue.webp')" />
              </a>
              <a v-bind:href="yt" target="_blank">
                <img class="image-footer" :srcset="icons('youtube-red.webp')" />
              </a>

            </div>
            <div class="break-1"></div>
            <p class="footer-more">Belanja Online Furniture Terlengkap dan Terpercaya se-Indonesia
              <b>#SahabatRamahRumahmu</b>
            </p>
            <div class="break-24"></div>
          </div>

          <div class="col-lg-4 col-md-6">
            <span class="title-h3-footer">Brand Kami</span>
            <div class="break-1"></div>
            <img :src="require('@/assets/images/logo-brand-kami.png')" alt="Brand Kami More Furniture"
              title="Brand Kami More Furniture" width="100%">
            <div class="break-24"></div>
          </div>


          <div class="col-lg-4 col-md-12">
            <span class="title-h3-footer">Metode Pembayaran</span>
            <div class="break-1"></div>
            <img :src="require('@/assets/images/logo-metode-pembayaran.png')" alt="Metode Pembayaran More Furniture"
              title="Metode Pembayaran More Furniture" width="100%">
            <div class="break-24"></div>
          </div>

          <div class="col-lg-4 col-md-12">
            <span class="title-h3-footer">Hubungi Kami</span>
            <div class="break-1"></div>
            <div>
              <div class="about-footer-info">
                <div class="about-footer-info">
                  <div class="more-footer-text">
                    <div class="more-footer-text-content d-flex flex-row" style="gap:8px">
                      <i class="ol-fa fas fa-map"></i>
                      <div class="d-flex flex-column">
                        <p>Lokasi Showroom</p>
                        <p>Jl Mayjen Hr Muhammad No 33 A Surabaya</p>
                      </div>
                    </div>

                    <div class="more-footer-text-content d-flex flex-row" style="gap:8px">
                      <i class="ol-fa fas fa-clock"></i>
                      <div class="d-flex flex-column">
                        <p>Jam Operasional</p>
                        <p>Senin-Minggu (09:00-21:00)</p>
                      </div>
                    </div>
                    <!-- <div class="more-footer-text-content d-flex flex-row" style="gap:8px"><i class="ol-fa fas fa-clock"></i> Jam
                                        Operasional</div>
                              <div class="more-footer-text-content d-flex flex-row" style="gap:8px">Senin-Minggu (08:00-21:00)</div>
                              <div class="more-footer-text-content d-flex flex-row" style="gap:8px">(diatas itu slow respon)</div> -->
                    <div class="more-footer-text-content d-flex flex-row" style="gap:8px"><i
                        class="ol-fa fab fa-whatsapp"></i>
                      WA:
                      <a :href="`https://api.whatsapp.com/send/?phone=${wa_no}&text=${waText}&app_absent=0`"
                        target="_blank" class="contact-footer"> <u>+62812-3024-2365</u></a>
                    </div>
                    <div class="more-footer-text-content d-flex flex-row" style="gap:8px"><i
                        class="ol-fa fa fa-envelope"></i> CS:
                      <a href="mailto:hello@morefurniture.id"> <u>hello@morefurniture.id</u></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="break-24"></div>
          </div>
          <div class="col-lg-4 col-6">
            <span class="title-h3-footer">MORE Furniture</span>
            <div class="break-1"></div>
            <div>
              <div class="about-footer-info">
                <div class="more-footer-text">
                  <div class="more-footer-text-content"><a href="/about" class="contact-footer">Tentang Kami</a></div>
                  <!-- <div class="more-footer-text-content"><a href="/moreversary" class="contact-footer">Moreversary</a></div> -->
                  <div class="more-footer-text-content"><a href="http://affiliate.morefurniture.id/" target="_blank"
                      rel="nofollow" class="contact-footer">MORE Affiliate Program</a></div>
                  <!-- <div class="more-footer-text-content"><a href="/moreverse" class="contact-footer">Virtual Tour</a></div> -->
                  <div class="more-footer-text-content"><a href="/desain-interior" class="contact-footer">Layanan Desain
                      Interior</a></div>
                  <div class="more-footer-text-content"><a href="/artikel" class="contact-footer">Artikel</a></div>

                  <div class="more-footer-text-content"><a href="http://linktr.ee/recruitmentolympicgroup"
                      target="_blank" rel="nofollow" class="contact-footer">Karir</a></div>
                </div>
              </div>
            </div>

          </div>

          <div class="col-lg-4 col-6">
            <span class="title-h3-footer">Bantuan Layanan</span>
            <div class="break-1"></div>
            <div>
              <div class="about-footer-info">
                <div class="more-footer-text">
                  <div class="more-footer-text-content"><a href="/faq" class="contact-footer">Cara Belanja</a></div>
                  <div class="more-footer-text-content"><a href="/faq" class="contact-footer">Cara Pembayaran &
                      Cicilan</a></div>
                  <div class="more-footer-text-content"><a
                      href="https://api.whatsapp.com/send/?phone=6281230242365&text=Halo+MORE%2C+bagi+info+tentang+jasa+rakit+dong&app_absent=0"
                      target="_blank" rel="nofollow" class="contact-footer">Jasa Rakit</a></div>
                  <div class="more-footer-text-content"><a href="https://www.mitraolympic.co.id/" target="_blank"
                      class="contact-footer">Jadi Mitra MORE Furniture</a></div>
                  <div class="more-footer-text-content"><a href="/snk" class="contact-footer">Syarat & Ketentuan</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
      <div class="ol-footer__copyright">
        <img src="https://asset.morefurniture.id/MORE/asset/morelogo_nd.png" style="width:auto;" alt="MORE FURNITURE"
          title="MORE FURNITURE">
        &copy; 2021. Hak Cipta Dilindungi
      </div>
      <hr>

      <div class="floating-wpp" style="left : auto; right: 15px;">
        <a :href="`https://api.whatsapp.com/send/?phone=${wa_no}&text=${waText}&app_absent=0`" target="_blank" @click="sendAction(productSelected)">
          <div class="floating-wpp-button" style="width: 40px; height: 40px; background-color: rgb(37, 211, 102);">
            <img
              src="https://rawcdn.githack.com/rafaelbotazini/floating-whatsapp/3d18b26d5c7d430a1ab0b664f8ca6b69014aed68/whatsapp.svg">
            <div class="floating-wpp-button-image"></div>
          </div>
        </a>
      </div>
    </footer>
    <hr>
    <!-- <div v-if="this.currentMeta" class="footer-meta" style="text-align:justify;" v-html="this.currentMeta.html_body"></div> -->

  </div>
</template>

<script>
import SkeletonLoader from "@/components/loading/skeletonLoader";
import { mapActions, mapGetters } from "vuex";
import { _ } from 'core-js';

export default {
  name: "Footer",
  components: {
    SkeletonLoader
  },
  data() {
    return {
      loading: true,
      wa_no: "",
      wa_link: "",
      tagline_title: "",
      tagline_desc: "",
      email: "",
      email_to: "",
      fb: "",
      ig: "",
      tk: "",
      yt: "",
      currentMetax: null,
    };
  },
  computed: {
    ...mapGetters("auth", ["authenticated", "user"]),
    ...mapGetters("home", ["metaPages", "waText","productSelected"]),
    ...mapGetters("product/detail", ["product"]),
    currentMeta() {
      return _.find(this.metaPages, { "slug": this.$route.name });
    }
  },
  methods: {
    ...mapActions("home", ["fetchMetaPages", "getSetting"]),
    ...mapActions("transaction/cart",["actionUser"]),
    async _getPage() {
    },
    async _getSetting() {
      let setting = await this.getSetting();
      let tag = _.find(setting, { id: 1 });
      let tag_d = _.find(setting, { id: 2 });
      let fb = _.find(setting, { id: 3 });
      let ig = _.find(setting, { id: 4 });
      let tk = _.find(setting, { id: 5 });
      let yt = _.find(setting, { id: 6 });
      let email = _.find(setting, { id: 7 });
      let wa = _.find(setting, { id: 8 });

      if (tag) this.tagline_title = tag.content;
      if (tag_d) this.tagline_desc = tag_d.content;
      if (wa) this.wa_no = wa.content;
      if (wa) this.wa_link = wa.description;
      if (email) this.email = email.content;
      if (email) this.email_to = 'mailto:' + email.content;
      if (yt) this.yt = yt.content;
      if (fb) this.fb = fb.content;
      if (ig) this.ig = ig.content;
      if (tk) this.tk = tk.content;
      this.loading = false;
    },
    sendAction(param){
      
      this.actionUser({
          "browser": this.$browserDetect.meta.name+ " version: " +this.$browserDetect.meta.version,
          "product":param,
          "type":"WA"
      });
    },
    allProduct() {
      this.$store.commit("product/list/resetFilter", true);
      if (this.$route.name != "Products") this.$router.push('/products');
    },
    setFooterColor() {
      let $navFooter = $(".ol-footer-nav__link");
      $navFooter.removeClass("active");
      let routeName = this.$route.name;
      switch (routeName) {
        case "more-homepage":
          $($navFooter[0]).addClass("active");
          break;
        case "Products":
          $($navFooter[1]).addClass("active");
          break;
        case "Cart":
          $($navFooter[2]).addClass("active");
          break;
        case "Wishlist":
          $($navFooter[3]).addClass("active");
          break;
        case "PlainMenu":
          $($navFooter[4]).addClass("active");
          break;
        case "more-login":
          $($navFooter[4]).addClass("active");
          break;
        default:
          break;
      }
    },
  },
  mounted() {
    this.setFooterColor();
    this._getSetting();
    this._getPage();
    if ($(".ol-totop").length) {
      var e = function () {
        100 < $(window).scrollTop()
          ? $(".ol-totop").addClass("show")
          : $(".ol-totop").removeClass("show");
      };
      e(),
        $(window).on("scroll", function () {
          e();
        }),
        $(".ol-totop").on("click", function (e) {
          e.preventDefault(),
            $("html,body").animate(
              {
                scrollTop: 0,
              },
              700
            );
        });
    }
  },
};
</script>

<style lang="css" scoped>
@import "../../assets/js/slick/slick.css";
@import '../../assets/css/bootstrap.css';

.moreversary-gif {
  width: 100px !important;
  height: 100px !important;

}

@media (max-width: 768px) {
  .moreversary-gif {
    width: 75px !important;
    height: 75px !important;

  }
}
</style>
