import apiurl from '@/util/apiurl'
import helpme from '@/util/helpme'
import _ from 'lodash';
import Vue from 'vue';
const CART = apiurl.WS_TRANSACTION.CART;
const HOME = apiurl.WS_HOME;

export default {
    namespaced:true,
    state: {
        variants: !JSON.parse(localStorage.getItem("cart")) ? [] : JSON.parse(localStorage.getItem("cart")),
        note: !JSON.parse(localStorage.getItem("note")) ? "" : JSON.parse(localStorage.getItem("note")),
        checkReedem: 0,
        checkMinReedem: 0,
        grandtotal: 0,
        grandtotalNormal: 0,
        grandtotalHemat: 0,
        kodeVoucher:'',
        voucher:null,
        reedem:[],
        diskon:0,
        grandtotalVoucher: 0,
    },
    getters: {
        variants: (state) => state.variants,
        voucher:(state) => state.voucher,
        reedem: (state) => state.reedem,
        // grandtotal: (state) => helpme.toRp(_.sumBy(state.variants, "subtotal")),
        grandtotal: (state) => {
            let r = 0;
            _.each(state.variants, function(v) {
                if(v.is_selected == 1) {
                    r += v.subtotal;
                    if(v.is_get_combo == 1 && v.r_uni_product_get_combo) {
                        r += (parseInt(v.r_uni_product_get_combo.combo_price) * v.quantity);
                    }
                }
            });
            state.grandtotal = r;
            return r;
        },
        grandtotalNormal: (state) => {
            let r = 0;
            _.each(state.variants, function(v) {
                if(v.is_selected == 1) {
                    r += (v.quantity * v.r_uni_product_variant_prices.ho_normal);
                }
            });
            state.grandtotalNormal = helpme.toRp(r);
            return helpme.toRp(r);
        },
        grandtotalHemat: (state) => {
            let r = (helpme.toNumberOnly(state.grandtotalNormal) - helpme.toNumberOnly(state.grandtotal))
            state.grandtotalHemat = helpme.toRp(r);
            return helpme.toRp(r);
        },
        grandtotalVoucher:(state) => helpme.toRp(state.grandtotalVoucher),
        diskon:(state) => helpme.toRp(state.diskon),
        kodeVoucher: (state) => state.kodeVoucher,
        note: (state) => state.note,
        checkMinReedem: (state) => state.checkMinReedem,
        outOfStock: (state) => {
            let founded = _.find(state.variants, function (q) {
                if (!q.bundle_summary && q.quantity > q.stock_available) return q;
            })
            return founded ? true : false;
        },
        checkReedem: (state) => {
            let r = 0;
            _.each(state.variants, function(v) {
                if(v.is_reedem == 1) {
                    r = 1
                }
            });
            return r;
        },
    },
    mutations: {
        setReedem: (state, reedem) => (state.reedem = reedem),
        plus2Cart: (state, variant) => {
            let existed = _.find(state.variants, { id: variant.id, is_get_combo: 0 });
            let data = !existed ? variant : existed;
            data.uni_product_variants_id = data.id;
            data.is_selected = 1;

            if (!existed) {
                if(!data.quantity || data.quantity == 0) data.quantity = variant.quantity; //Tambah Param Quantity
                data.subtotal = data.valid_price * data.quantity;
                data.subtotal_rp = helpme.toRp(data.subtotal);
                state.variants.unshift(data);
            } else {
                if (data.r_uni_product_flashsale || (data.r_uni_product_get_free && data.r_uni_product_get_free.is_multiple_buy == 0)) {
                    data.quantity = 1;
                } else {
                    data.quantity = data.quantity+variant.quantity;//Tambah Param Quantity
                }

                //is bundle product
                if (data.bundle_summary) {
                    let parentQty = data.quantity;
                    data.bundle_summary = _.map(data.bundle_summary, function (q) {
                        q.quantity = q.initial_quantity * parentQty;
                        return q;
                    });
                }

                data.subtotal = data.valid_price * data.quantity;
                data.subtotal_rp = helpme.toRp(data.subtotal);
                let index = _.findIndex(state.variants, { id: data.id, is_get_combo: 0 });
                Vue.set(state.variants, index, data);
                // state.variants[index] = data; non Reactive!
            }
            localStorage.setItem("cart", JSON.stringify(state.variants));
        },
        plus: (state, variant) => {
            // console.log(variant)
            let existed = _.find(state.variants, { id: variant.id, is_get_combo: variant.is_get_combo, id_combo: variant.id_combo });
            let data = !existed ? variant : existed;
            data.uni_product_variants_id = data.id;
            data.is_selected = 1;

            if (!existed) {
                data.quantity = 1;
                if (data.quantity > data.valid_stock) return false;
                // if (data.is_flashsale && data.flashsale.limit && (data.quantity > data.flashsale.limit)) return false;
                data.subtotal = data.valid_price * data.quantity;
                data.subtotal_rp = helpme.toRp(data.subtotal);
                state.variants.unshift(data);
            } else {
                // jika flashsale atau jika produk free multiple buy = 0 atau jika produk combo hemat multiple buy = 0
                if (data.r_uni_product_flashsale || (data.r_uni_product_get_free && data.r_uni_product_get_free.is_multiple_buy == 0) || (data.r_uni_product_get_combo && data.r_uni_product_get_combo.is_multiple_buy == 0)) {
                    data.quantity = 1;
                } else {
                    data.quantity++;
                }

                if (data.quantity > data.valid_stock) data.quantity--;
                //is bundle product
                if (data.bundle_summary) {
                    let parentQty = data.quantity;
                    data.bundle_summary = _.map(data.bundle_summary, function(q){
                        q.quantity = q.initial_quantity * parentQty;
                        return q;
                    });
                }
                data.subtotal = data.valid_price * data.quantity;
                data.subtotal_rp = helpme.toRp(data.subtotal);
                data.is_selected = 1;
                if(variant.is_get_combo && variant.r_uni_product_get_combo) {
                    data.r_uni_product_get_combo.subtotal = (parseInt(variant.r_uni_product_get_combo.combo_price) * data.quantity);
                    data.r_uni_product_get_combo.subtotal_rp = helpme.toRp(data.r_uni_product_get_combo.subtotal);
                }
                let index = _.findIndex(state.variants, { id: data.id, is_get_combo: variant.is_get_combo });
                Vue.set(state.variants, index, data);
                // state.variants[index] = data; non Reactive!
            }
            localStorage.setItem("cart", JSON.stringify(state.variants));
        },
        plus2CartFromCombo: (state, variant) => {
            // let existed = _.find(state.variants, { id: variant.id, is_get_combo: 1 });
            let existed = _.find(state.variants, { id: variant.id, is_get_combo: 1, id_combo: variant.id_combo});
            // console.log(existed);
            let data = !existed ? variant : existed;
            data.uni_product_variants_id = data.id;
            data.is_selected = 1;

            if (!existed) {
                if(!data.quantity || data.quantity == 0) data.quantity = 1;
                data.subtotal = data.valid_price * data.quantity;
                data.subtotal_rp = helpme.toRp(data.subtotal);
                state.variants.unshift(data);
            }
            else {
                if (data.r_uni_product_flashsale || (data.r_uni_product_get_combo && data.r_uni_product_get_combo.is_multiple_buy == 0)) {
                    data.quantity = 1;
                } else {
                    data.quantity++;
                }

                //is bundle product
                if (data.bundle_summary) {
                    let parentQty = data.quantity;
                    data.bundle_summary = _.map(data.bundle_summary, function (q) {
                        q.quantity = q.initial_quantity * parentQty;
                        return q;
                    });
                }

                data.subtotal = data.valid_price * data.quantity;
                data.subtotal_rp = helpme.toRp(data.subtotal);
                let index = _.findIndex(state.variants, { id: data.id, is_get_combo: 1 });
                Vue.set(state.variants, index, data);
            }
            localStorage.setItem("cart", JSON.stringify(state.variants));
        },
        min: (state, variant) => {
            // console.log(variant)
            let data = _.find(state.variants, { id: variant.id, is_get_combo: variant.is_get_combo, id_combo: variant.id_combo });
            if (data && data.quantity > 1) {
                let index = _.findIndex(state.variants, { id: variant.id, is_get_combo: variant.is_get_combo });
                data.quantity--;
                if (data.bundle_summary) {
                    let parentQty = data.quantity;
                    data.bundle_summary = _.map(data.bundle_summary, function(q){
                        q.quantity = q.initial_quantity * parentQty;
                        return q;
                    });
                }
                data.subtotal = data.valid_price * data.quantity;
                data.subtotal_rp = helpme.toRp(data.subtotal);
                if(variant.is_get_combo && variant.r_uni_product_get_combo) {
                    data.r_uni_product_get_combo.subtotal = (parseInt(variant.r_uni_product_get_combo.combo_price) * data.quantity);
                    data.r_uni_product_get_combo.subtotal_rp = helpme.toRp(data.r_uni_product_get_combo.subtotal);
                }
                // state.variants[index] = data;
                Vue.set(state.variants, index, data);
            }
            localStorage.setItem("cart", JSON.stringify(state.variants));
        },
        remove: (state, variant) => {
            state.variants = _.filter(state.variants, (item) => {
                return !(item.id === variant.id && item.is_get_combo === variant.is_get_combo && item.id_combo == variant.id_combo);
            });

            state.variants = _.filter(state.variants, (item) => {
                return !(item.is_reedem == 1);
            });
            localStorage.setItem("cart", JSON.stringify(state.variants));
        },
        removeReedem: (state) => {
            //Hapus Tebus Murah
            state.variants = _.filter(state.variants, (item) => {
                return !(item.is_reedem == 1);
            });
            localStorage.setItem("cart", JSON.stringify(state.variants));
        },
        setStock: (state, variant) => {
            let index = _.findIndex(state.variants, { id: variant.id });
            if (index >= 0) {
                Vue.set(state.variants, index, state.variants[index]);
                localStorage.setItem("cart", JSON.stringify(state.variants));
            }
        },
        setSummary: (state, response) => {
            state.grandtotal = 0;
            if (response) {
                state.grandtotal = response.grand_total;
                // let maped = _.map(state.variants, (item) => {
                let maped = _.map(response.r_more_transaction_cart_details, (item) => {
                    // let find = _.find(response.r_more_transaction_cart_details, { uni_product_variants_id: item.id });
                    item.r_uni_product_get_free = item.r_uni_product_variants.r_uni_product_get_free;
                    item.r_uni_product_get_po = item.r_uni_product_variants.r_uni_product_get_po;
                    item.r_uni_product_get_combo = item.r_uni_product_variants.r_uni_product_get_combo;
                    item.r_uni_product_flashsale = item.r_uni_product_variants.r_uni_product_flashsale;
                    item.stock_available = item.r_uni_product_variants.stock_available;
                    item.valid_stock = item.r_uni_product_variants.valid_stock;
                    item.quantity = item.quantity;
                    item.subtotal = item.sub_total;
                    item.subtotal_rp = helpme.toRp(item.subtotal);
                    return item;
                });
                state.variants = maped;
            } 
            localStorage.setItem("cart", JSON.stringify(state.variants));
        },
        setVoucher:(state,voucher) =>{
            if(voucher){
                state.voucher = voucher;
                state.diskon = voucher.potongan;
                state.grandtotalVoucher = voucher.grandTotalDiskon;
            }else{
                state.voucher = null;
                state.grandtotalVoucher=0;
            }
        },
        setKodeVoucher:(state,kodeVoucher) =>{
            state.kodeVoucher = kodeVoucher
        },
        setNote: (state, payload) => {
            state.note = payload;
            localStorage.setItem("note", JSON.stringify(state.note));
        },
        setCheckReedem: (state, payload) => {
            state.checkReedem = payload;
            localStorage.setItem("checkReedem", JSON.stringify(state.checkReedem));
        },
        setCheckMinReedem: (state, payload) => {
            state.checkMinReedem = payload;
            localStorage.setItem("checkMinReedem", JSON.stringify(state.checkMinReedem));
        },
        setCart2Empty: (state) => {
            state.variants = [];
            localStorage.setItem("cart", JSON.stringify(state.variants));
        },
        setNullPostOrder:(state) => {
            state.variants = [];
            localStorage.setItem("cart", JSON.stringify(state.variants));

            state.note = null;
            localStorage.setItem("note", JSON.stringify(state.note));
        },
        setVariants: (state, payload) => {
            state.variants = payload;
            localStorage.setItem("cart", JSON.stringify(state.variants));
        },
        beliLangsung: (state, variant) => {
            if(state.variants && state.variants.length > 0) {
                _.each(state.variants, function(value, key) {
                    state.variants[key] = {...value, is_selected: 0};
                });
            }

            let existed = _.find(state.variants, { id: variant.id, is_get_combo: 0 });
            let data = !existed ? variant : existed;
            data.uni_product_variants_id = data.id;
            data.is_selected = 1;
            
            if (!existed) {
                if(!data.quantity || data.quantity == 0) data.quantity = variant.quantity;//Tambah Param Quantity
                data.subtotal = data.valid_price * data.quantity;
                data.subtotal_rp = helpme.toRp(data.subtotal);
                state.variants.unshift(data);
            }
            else {
                if (data.r_uni_product_flashsale || (data.r_uni_product_get_free && data.r_uni_product_get_free.is_multiple_buy == 0)) {
                    data.quantity = 1;
                } else {
                    data.quantity = data.quantity+variant.quantity;//Tambah Param Quantity
                }

                //is bundle product
                if (data.bundle_summary) {
                    let parentQty = data.quantity;
                    data.bundle_summary = _.map(data.bundle_summary, function (q) {
                        q.quantity = q.initial_quantity * parentQty;
                        return q;
                    });
                }

                data.subtotal = data.valid_price * data.quantity;
                data.subtotal_rp = helpme.toRp(data.subtotal);
                let index = _.findIndex(state.variants, { id: data.id, is_get_combo: 0 });
                Vue.set(state.variants, index, data);
            }
            localStorage.setItem("cart", JSON.stringify(state.variants));
        },
    },
    actions: {
        async save({ commit, state }, onFinally = () => { }) {
            let input = { r_more_transaction_cart_details:state.variants, };
            let result = { success: false };
            await CART.SAVE(input).then(response => {
                result = response.data;
                // commit("setSummary", result.data);
            }).catch(error => {
                // console.error("saveCart", error);
                result = error.response.data;
            });
            onFinally();
            return result;
        },
        async checkStock({ commit, state }, variant, onFinally = () => { }) {
            let sample = _.find(state.variants, { id: variant.id, is_get_combo: variant.is_get_combo });
            let result = { success: false, data:{} };
            await CART.CHECK_STOCK(sample).then(response => {
                result = response.data;
            }).catch(error => {
                // console.error("checkStock", error);
                result = error.response.data;
            });

            if (_.has(result.data, "valid_stock")) commit("setStock", result.data);

            if (!result.success) {
                (sample.quantity == 1 ) ? commit("remove",variant) : commit("min", variant);
            }
            onFinally();
            return result;
        },
        async multiCheckStock({ commit, state }, variant, onFinally = () => { }) {
            let sample = { r_more_transaction_cart_details: state.variants }; 
            let result = { success: false, data:{} };
            await CART.MULTI_CHECK_STOCK(sample).then(response => {
                result = response.data;
            }).catch(error => {
                // console.error("multiCheckStock", error);
                result = error.response.data;
            });

            if (_.has(result.data, "valid_stock")) commit("setStock", result.data);

            if (!result.success) {
                (sample.quantity == 1 ) ? commit("remove",variant) : commit("min", variant);
            }
            onFinally();
            return result;
        },
        async set_voucher({ commit, state },kode, onFinally = () => { }) {
            let result = { success: true };
            var formdata = new FormData();
                formdata.append("code", kode.toUpperCase());
                formdata.append("address_code", 54);
                formdata.append("payment_code", 1);
                formdata.append("device", "web");

            await CART.USE_VOUCHER(formdata).then(response => {
                result = response.data;
                commit("setVoucher", result.data);
            }).catch(error => {
                // console.error("get", error);
                commit("setVoucher", null);
                result = error.response.data;
            });
            onFinally();
            return result;
        },
        async buyAgain({ commit, state, dispatch }, payload) {
            let comboVariantId = 0;
            let success = await Promise.all(
                _.map(payload, async function(q) {
                    let result;
                    // q.r_uni_product_variant.r_uni_product_image = { image_url: q.r_uni_product_variant.image_url };
                    q.r_uni_product_variant.quantity = q.quantity;
                    if (q.r_more_transaction_order_products_bundling.length > 0) {
                        q.r_uni_product_variant.bundle_summary = _.map(q.r_more_transaction_order_products_bundling, function (item) {
                            item.r_uni_product_variant.initial_quantity = item.quantity;
                            item.r_uni_product_variant.quantity = item.quantity;
                            return item.r_uni_product_variant;
                        });
                    }

                    if(q.r_uni_product_get_combo) {
                        if(q.r_uni_product_get_combo.free_type == 'COMBO') {
                            // ? Jika ada product COMBO
                            comboVariantId = q.r_uni_product_get_combo.free_uni_product_variants_id;
                            await commit("plus2CartFromCombo", {...q.r_uni_product_variant, is_get_combo: 1, id_combo: q.r_uni_product_get_combo.id});
                            result = await dispatch("checkStock", {...q.r_uni_product_variant, is_get_combo: 1});
                        }
                    }
                    else {
                        if(q.r_uni_product_variant.id !== comboVariantId) {
                            await commit("plus2Cart", {...q.r_uni_product_variant, is_get_combo: 0, id_combo:null});
                            result = await dispatch("checkStock", {...q.r_uni_product_variant, is_get_combo: 0});
                        }
                    }

                    success = result ? result.success : false;
                    if(!success) {
                        return;
                    }
                    return success;
                })
            );

            if(success) {
                await dispatch("save");
                return success;
            }
            // payload.forEach(item => {
            //     console.log(item);
            //     commit("plus2Cart", item);
            // });
        },
        async get({ commit, state }) {
            let result = { success: false };
            await CART.GET().then(response => {
                result = response.data;
                let variants = [];
                _.each(result.data.r_more_transaction_cart_details, function(d) {
                    let variant = d.r_uni_product_variants;
                    variant.r_uni_product_get_combo = d.r_uni_product_get_combo;
                    variant.is_selected = d.is_selected;
                    variant.is_reedem = d.is_reedem;
                    variant.is_get_combo = d.is_get_combo;
                    variant.id_combo = d.id_combo;
                    variant.quantity = d.quantity;
                    variant.subtotal = d.sub_total;
                    variant.subtotal_rp = helpme.toRp(variant.subtotal);
                    variants.push(variant);
                });
                // commit("setVariants", result.data ? _.map(result.data.r_more_transaction_cart_details, "r_uni_product_variants") : []);
                commit("setVariants", variants);
                // commit("setSummary", result.data);
            }).catch(error => {
                // console.error("getCart", error);
                result = error.response.data;
            });
            return result;
        },
        async cartFbPixel({ commit, state }, payload) {
            let result = { success: false, data: {} };
            
            payload["ip"] = "";
            await HOME.GET_IP().then(response => {
                payload["ip"] = response.data.ip;
            }).catch(error => { });

            // console.log("CartFbPixel", payload);
            await CART.CART_FB_PIXEL(payload).then(response => {
                result = response.data;
            }).catch(error => {
                // console.error("checkStock", error);
                result = error.response.data;
            });

            return result;
        },
        async actionUser({ commit, state }, payload) {
            let result = { success: false, data: {} };
            
            payload["ip"] = "";
            await HOME.GET_IP().then(response => {
                payload["ip"] = response.data.ip;
            }).catch(error => { });

            // console.log("CartFbPixel", payload);
            await CART.ACTION_USER(payload).then(response => {
                result = response.data;
            }).catch(error => {
                // console.error("checkStock", error);
                result = error.response.data;
            });

            return result;
        },
        async checkLocation({ commit, state }, payload) {
            let result = { success: false, data: {} };

            await CART.CHECK_LOCATION(payload).then(response => {
                result = response.data.data;
            }).catch(error => {
                result = error.response.data.data;
            });

            return result;
        },
        async checkProduct({ commit, state }, product) {
            let result = { success: false, data: {} };

            await CART.CHECK_PRODUCT({uni_product_variants_id: product?.id,id_combo: product?.id_combo}).then(response => {
                result = response.data.data;
            }).catch(error => {
                result = error.response.data.data;
            });

            return result;
        },
        async checkProductAll({ commit, state }, data) {
            let result = { success: false, data: {} };

            await CART.CHECK_PRODUCT_ALL({'is_selected': data}).then(response => {
                result = response.data.data;
            }).catch(error => {
                result = error.response.data.data;
            });

            return result;
        },
        async fetchReedem({ commit, state }, onFinally = () => { }) {
            let result = { success: false };
            
            await HOME.GET_REEDEM().then(response => {
                result = response.data;
                commit("setReedem", result.data);
            }).catch(error => {
                // console.error("fetchReedem", error);
                result = error.response.data;
            });
            onFinally();
            return result;
        },
    },
};
