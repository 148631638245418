<template>
    <BaseLayout>
        <div class="row body-login">            
            <div class="col-lg-6 order-lg-2 col-xs-12 order-xs-1">
                
                <div class="form-login">
                    <ul class="tab-group">
                        <li class="tab  active">
                            <a class="a-login tab-left" href="#login">Masuk</a>
                        </li>
                        <li class="tab  ">
                            <a class="a-login tab-right" href="#signup">Daftar</a>
                        </li>
                    </ul>
                    <div class="tab-content">
                        <div id="login">
                            <alert-info v-if="error == null" :pesan="'Silakan Login'"/>
                            <alert-error v-if="error != null" :pesan="error"/>
                            <form v-on:submit.prevent="login()">
                                <div class="field-wrap">
                                    <label class="label-login"> Email <span class="req">*</span>
                                    </label>
                                    <input id="email" type="email" class="ol-input form-control" name="email" v-model="input.email" required autocomplete="email" autofocus>
                                </div>
                                <div class="field-wrap">
                                    <label class="label-login"> Kata Sandi <span class="req">*</span>
                                    </label>
                                    <div class="ol-input-ico">
                                        <!-- <input id="password" type="password" class="ol-input" name="password" v-model="input.password" required autocomplete="current-password">
                                        <span id="ico" v-on:click="hide_me()" class="ol-fa"><i class="fa fa-eye-slash"></i></span> -->
                                        <Password style="max-width:1000px!important" v-model="input.password" :toggle="true" :showStrengthMeter="false" :badge="false" />

                                    </div>
                                </div>
                                <p class="forgot">
                                    <router-link to="forgotPassword">Lupa Kata Sandi ?</router-link>
                                </p>
                                <!-- <button type="submit"  id="button_cart" class="ol-btn button-block"> Masuk </button> -->
                                <div class="">
                                    <button type="submit" class="ol-btn button-block">Masuk</button>
                                </div>
                                <h6 class="h6-login">Atau masuk dengan</h6>
                                
                                
                            </form>
                            <button class="ol-btn ol-btn--secondary button-block" v-on:click="loginGoogle()"><i class="ol-fa fab fa-google"></i> Masuk dengan Google</button>
                        </div>
                        <div id="signup">
                            <Alert
                                v-show="alert.messages != null"
                                :type="alert.type"
                                :collection="alert.messages"
                            ></Alert>
                            <form v-on:submit.prevent="register()">
                                <div class="field-wrap">
                                    <label class="label-login"> Masukkan Email <span class="req">*</span>
                                    </label>
                                    <input id="email" type="email" class="ol-input form-control" name="email" v-model="pwd.email" required autocomplete="email" autofocus />
                                </div>
                                <div class="field-wrap">
                                    <label class="label-login"> Kata Sandi <span class="req">*</span>
                                    </label>
                                    <Password style="max-width:1000px!important" v-model="pwd.new_password" :badge="false" :toggle="true" @score="showScore" />
                                </div>
                                <p class="confirm-register">
                                    Dengan mendaftar, Anda setuju dengan <a class="a-login" href="/snk"> <b>Syarat Ketentuan dan Kebijakan dari More</b></a>
                                </p>
                                <button type="submit" id="submit_daftar" class="ol-btn button-block">Daftar</button>
                                <h6 class="h6-login">Atau daftar dengan</h6>
                            </form>
                            <button class="ol-btn ol-btn--secondary button-block" v-on:click="loginGoogle()"><i class="ol-fa fab fa-google"></i> Masuk dengan Google</button>
                        </div>
                    </div>
                    <!-- tab-content -->
                </div>
            </div>
            <div class="col-lg-6 order-lg-1 col-xs-12 order-xs-2" style="text-align: center;margin:auto;">
                <img src="https://asset.morefurniture.id/MORE/asset/image-login.png" width="65%">
            </div>
        </div>
    </BaseLayout>
</template>

<script>
import BaseLayout from '@/components/landing/BaseLayout.vue';
import {mapGetters,mapActions} from "vuex";
import {initFbsdk} from '../../lib/facebook';
import Password from 'vue-password-strength-meter';

export default {
	name: "Login",
    components:{
        Password,
        BaseLayout,
    },
    metaInfo() {
        return{
            meta: [
                { name: 'title', content: this.currentMeta ? this.currentMeta.meta_title : ""},
                { name: 'description', content: this.currentMeta ? this.currentMeta.meta_desc : ""},
                
            ]
        }
    },
    data(){
        return {
            input: {
                username: "",
                password: ""
            },
            pwd: {
                email: null,
                new_password: null,
            },
            alert: {
                messages: { email: ["Kami akan mengirimkan link ke email Anda"] },
                message: null,
                type: "info",
            },
            score: 0,
            setuju: true,
            mengikuti: false,
            error:null,
            loading:false,
            logo:"../../assets/images/morelogo.png",
            currentMeta: null,
        }
    },
    computed:{
		...mapGetters("home", ["metaPages"]),
        ...mapGetters("auth",["user"]),
        ...mapGetters("transaction/cart", [
			"note",
		]),
    },
    methods:{
        ...mapActions("auth",["fetchUser","loginUser","loginUserGoogle","loginUserFb","registerUser"]),
		...mapActions("home", ["fetchMetaPages"]),
        async getUser(){
            let user = await this.fetchUser();
            if(user.statusCode == 200) {
                if(user.data.email && user.data.email != ""){
                    let searchParams = new URLSearchParams(window.location.search);

                    if (searchParams.has("redirect")) {
                    this.$router.push({ path: `${searchParams.get("redirect")}` });
                    } else this.$router.push({ path: "/" });
                }else{
                    this.$router.push("/profile")
                }
            }else{
                this.error = user.data.message;
            }
        },
        async login(){
            if(this.input.email !="" && this.input.password != ""){
                this.loading =true;
                var formdata = new FormData();
                formdata.append("email", this.input.email);
                formdata.append("password", this.input.password);

                let result = await this.loginUser(formdata);
                // console.log(result);
                if(result.statusCode == 200){
                    this.$gtag.event('login', { method: 'Web' });
                    // this.$analytics.fbq.event('login', {method: 'Web'});
                    this.getUser();
                }else{
                    var err ="";
                    if(result.data.email){
                        err += result.data.email+"\n";
                    }
                    if(result.data.message){
                        err += result.data.message;
                    }
                    this.error = err;
                    this.loading = false;
                }

            }else{
                this.error = "Username dan password harus diisi."
            }
        },
        showScore(score) {
            this.score = score;
        },
        cek_box() {
            if (this.setuju) {
                $("#submit_daftar").prop("disabled", true);
            } else {
                $("#submit_daftar").prop("disabled", false);
            }
        },
        async register() {
            if (this.score >= 1) {
                if (this.pwd.email != "" && this.pwd.password != "" && this.setuju) {
                this.loading = true;
                var formdata = new FormData();
                formdata.append("email", this.pwd.email);
                formdata.append("password", this.pwd.password);
                formdata.append("is_subscribe", this.mengikuti ? 1 : 0);
                let result = await this.registerUser(formdata);
                if (result.statusCode == 200) {
                    // this.analytics('CompleteRegistration');
                    fbq('track', 'CompleteRegistration', {
                    content_name:"Registration",
                    currency:"IDR",
                    status:true,
                    value:this.pwd.email
                    });
                    (this.messages = {
                    email: ["Email verifikasi telah dikirim ke email Anda"],
                    }),
                    (this.alert.type = "success");
                    this.loading = false;
                    this.getUser();
                } else {
                    this.alert.messages = result.data;
                    this.alert.type = "danger";
                    this.loading = false;
                }
                } else {
                this.alert.messages = { email: ["Email dan password harus diisi."] };
                this.alert.type = "danger";
                }
            } else {
                this.alert.messages = {
                pwd: ["Kekuatan kata sandi minimal medium (memenuhi 2 bar)"],
                };
                this.alert.type = "danger";
            }
        },
        hide_me(){
            var x = document.getElementById("password");
            if (x.type === "password") {
                x.type = "text";
                document.getElementById("ico").innerHTML= '<i class="fa fa-eye"></i>';
            } else {
                x.type = "password";

                document.getElementById("ico").innerHTML= '<i class="fa fa-eye-slash"></i>';
            }
        },
        loginGoogle(){
            this.$gAuth.signIn().then(async GoogleUser => {
                // console.log("email", GoogleUser.getBasicProfile().getEmail());
                // console.log("name", GoogleUser.getBasicProfile().getName());
                // console.log("picture", GoogleUser.getBasicProfile().getImageUrl());
                // console.log("uuid", GoogleUser.getBasicProfile().getId());
                this.loading =true;
                var formdata = new FormData();
                formdata.append('email', GoogleUser.getBasicProfile().getEmail());
                formdata.append('name', GoogleUser.getBasicProfile().getName());
                formdata.append('picture', GoogleUser.getBasicProfile().getImageUrl());
                formdata.append('uuid', GoogleUser.getBasicProfile().getId());
                let result = await this.loginUserGoogle(formdata);

                if(result.statusCode == 200){
                    this.$gtag.event('login', { method: 'Google' });
                    // this.$analytics.fbq.event('login', {method: 'Google'});
                    this.getUser();
                }else{
                    var err ="";
                    if(result.data.email){
                        err += result.data.email+"\n";
                    }
                    if(result.data.message){
                        err += result.data.message;
                    }
                    this.error = err;
                    this.loading = false;
                }
            }).catch(error => {
                // console.log('error', error)
            });
        },
        loginFb(){
            window.FB.login( async response => {
                // console.log(response);
                if(response.authResponse){
                    this.loading =true;
                    await window.FB.api('/me', {fields: 'name,gender,location,picture,email',access_token: response.authResponse.accessToken}, async (response) => {
                        // console.log(response);
                        // console.log(response.name + ', ' + response.email);
                        var formdata = new FormData();
                        formdata.append("email", response.email);
                        formdata.append("name", response.name);
                        formdata.append('picture',response.picture.data.url);
                        formdata.append('uuid',response.id);
                        let result = await this.loginUserFb(formdata);
                        if(result.statusCode == 200){
                            this.$gtag.event('login', { method: 'Facebook' });
                            // this.$analytics.fbq.event('login', {method: 'Facebook'});
                            this.getUser();
                        }else{
                            var err ="";
                            if(result.data.email){
                                err += result.data.email+"\n";
                            }
                            if(result.data.message){
                                err += result.data.message;
                            }
                            this.error = err;
                            this.loading = false;
                        }
                    });
                }
                
                
            }, {scope: 'email'});
        },
        async getMeta(){
            await this.fetchMetaPages();
            this.currentMeta = _.find(this.metaPages, {"slug":"more-login"});
        }
        
    },
    created(){
        this.getMeta();
    },
    updated(){
        this.getMeta();
    },
    mounted () {
        $('.tab a').on('click', function (e) {
            
            e.preventDefault();
            
            $(this).parent().addClass('active');
            $(this).parent().siblings().removeClass('active');
            
            var target = $(this).attr('href');

            $('.tab-content > div').not(target).hide();
            
            $(target).fadeIn(200);
        
        });
        this.getMeta();
        initFbsdk();
    },
    

};
</script>
<style scoped lang="css">
@import '../../assets/css/bootstrap.css';
@import '../../assets/css/login.css';
</style>