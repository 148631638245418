<template>
    <BaseLayout>
        <script v-html="jsonld" type="application/ld+json"></script>
        <!-- <LoadingImg v-if="loading.wishlist" /> -->
        <div class="ol-addcart" id="cart" hidden="true">
            <figure>
                <img :srcset="require('@/assets/images/og-addtocart.svg')" alt="Berhasil Ditambahkan" title="Berhasil Ditambahkan" />
            </figure>
            <p>Berhasil Ditambahkan</p>
        </div>
        <div class="ol-addcart" id="wishlist" hidden="true">
            <figure>
                <img :srcset="require('@/assets/images/og-addtocart.svg')" alt="Berhasil Ditambahkan" title="Berhasil Ditambahkan" />
            </figure>
            <p>Berhasil ditambahkan</p>
        </div>
        <div class="ol-addcart" id="copy_url" hidden="true">
            <i class=""></i> Tautan Berhasil tersimpan
        </div>
        <div class="ol-addcart" id="copy_voucher_url" hidden="true">
            <i class=""></i> Kode Voucher Berhasil tersimpan
        </div>
        <DetailLoader v-if="loading.detail" />
        <div class="ol-addcart" id="copy_url" hidden="true">
            <i class=""></i>Kode Voucher Berhasil disalin
        </div>
        
        <section  class="ol-product ol-product-detail">
            <div v-if="!loading.detail" class="ol-wrapper ol-wrapper--large">
                <div class="ol-breadcrumb" style="padding-bottom: 16px !important;">
                    <a href="/" class="ol-breadcrumb__link">Home</a> 
                    ›
                    <a v-if="product.r_uni_product_categories && product.r_uni_product_categories[0] && product.r_uni_product_categories[0].r_uni_categories" 
                    class="ol-breadcrumb__link" 
                    :href="searchCategory(product.r_uni_product_categories[0].r_uni_categories.name)" > 
                    {{ product.r_uni_product_categories[0].r_uni_categories.name }} ›
                    </a>
                    <a href="#" class="ol-breadcrumb__link">{{product.name}}</a>
                </div>
                <div class="add-fix"></div>

                <div class="ol-product-content ol-product-content-detail add-fix ">
                    <div class="ol-product-content-detail__images">
                        <div class="ol-product-content-detail__images-list">

                            <div class="ol-product-content-detail__images-list-single">
                                <figure v-for="image in productVideosCatalog" :key="'images-list-video-'+image.id" class="ol-product-content-detail__images-list-single-figure" :data-src="youtubeThumb(image.link, 'sddefault')">
                                    <div class="ol-product-img-container youtube" @click="showPopUpVideo(image)">
                                        <img class="og-brand-bearland" :src="youtubeThumb(image.link, 'sddefault')" :alt="image.desc" :title="product.name">
                                        <img class="play-img-single" :srcset="require('@/assets/images/play.svg')">
                                    </div>
                                </figure>
                                <figure v-for="image in product.r_uni_product_suasanas" :key="'images-list-suasana-'+image.id" class="ol-product-content-detail__images-list-single-figure" :data-src="image.image_url">
                                    <div class="ol-product-img-container">
                                        <img class="og-brand-bearland" :src="image.image_url" :alt="image.alt_text" :title="product.name">
                                    </div>
                                </figure>
                                <figure v-for="image in product.r_uni_product_images" :key="'images-list-single-'+image.id" class="ol-product-content-detail__images-list-single-figure" :data-src="image.image_url">
                                    <div class="ol-product-img-container">
                                        <img class="og-brand-bearland" :src="image.image_url" :alt="image.alt_text" :title="product.name">
                                    </div>
                                </figure>
                                <figure v-for="image in product.r_uni_product_image_bundling" :key="'images-list-suasana-'+image.id" class="ol-product-content-detail__images-list-single-figure" :data-src="image.image_url" v-if="image.type != 'PRODUK-INFO'">
                                    <div class="ol-product-img-container">
                                        <img class="og-brand-bearland" :src="image.image_url" :alt="image.alt_text" :title="product.alt_text">
                                    </div>
                                </figure>
                            </div>
                            <div class="ol-product-content-detail__images-list-group">
                                <figure v-for="image in productVideosCatalog" :key="'detail-youtube-'+image.id" :id="'detail-youtube-'+(image.id).toString()" class="ol-product-content-detail__images-list-group-figure item-detail-images-youtube" @click="showPopUpVideo(image)">
                                    <img :src="youtubeThumb(image.link)" :alt="image.desc" :title="product.name" style="margin:0 auto;">
                                    <img class="play-img" :srcset="require('@/assets/images/play.svg')">
                                </figure>
                                <figure v-for="image in product.r_uni_product_suasanas" :key="'detail-suasana-'+image.id" :id="'detail-'+(image.id).toString()" class="ol-product-content-detail__images-list-group-figure item-detail-images-suasana">
                                    <img :src="image.image_url" :alt="image.alt_text" :title="product.name" style="margin:0 auto;">
                                </figure>
                                <figure v-for="image in product.r_uni_product_images" :key="'detail-'+image.id" :id="'detail-'+(image.id).toString()" class="ol-product-content-detail__images-list-group-figure item-detail-images">
                                    <img :src="image.image_url" :alt="image.alt_text" :title="product.name" style="margin:0 auto;">
                                </figure>
                                <figure v-for="image in product.r_uni_product_image_bundling" :key="'detail-'+image.id" :id="'detail-'+(image.id).toString()" class="ol-product-content-detail__images-list-group-figure item-detail-images" v-if="image.type != 'PRODUK-INFO'">
                                    <img :src="image.image_url" :alt="image.alt_text" :title="product.alt_text" style="margin:0 auto;">
                                </figure>
                            </div>
                        </div>
                    </div>
                    <div class="ol-product-content-detail__desc" v-if="productFlash">
                        <div class="flash-sale">
                            <div class="flash-sale-detail">
                                <div style="width: 60%; padding: 0 10px;">
                                    <div class="flashSaleImgHeader">
                                        <img :src="require('../../assets/images/flashsale-alt.png')" />
                                    </div>
                                    <div class="flash-sale-stock-container" >
                                        <div class="ol-home-flash-list__item-bar flashSaleStock">
                                            <span>
                                                <i class="currentStock" :style="'width:'+((productFlash.remains/productFlash.limit)*100)+'%;'"></i>
                                            </span>
                                        </div>
                                        <div>
                                            <p class="flash-sale-stock-info" v-show="((productFlash.remains/productFlash.limit)*100)<=50">Segera habis!</p>
                                        </div>
                                    </div>
                                </div>
                                <div style="color:white;font-size:14px;width:40%">
                                    <div>Berakhir dalam :</div>
                                    <ul id="countdownFlash2" >
                                    <b>{{ formatedCountdown || 'countdown over' }}</b>
                                    </ul>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="ol-product-content-detail__desc" style="margin-bottom:0px;">
                        
                        <div class="morepromise" v-if="productFree">
                            <b>{{ product.seo_4 }}</b> 
                            <router-link :to="'/product/'+productFree.r_uni_product_variants.slug">
                                    {{ productFree.r_uni_product_variants.name_short}} 
                            </router-link>
                        </div>

                        <h1 v-if="!getSelectedFinalVariant" style="line-height:normal;" class="ol-home-product__content-item-list--item-title name-product" :title="product.name"> {{product.name}} </h1>
                        <h1 v-if="getSelectedFinalVariant" style="line-height:normal;" class="ol-home-product__content-item-list--item-title name-product" :title="getSelectedFinalVariant.name_long"> {{getSelectedFinalVariant.name_long}} </h1>
                        <div class="review-detail-product" v-if="product.total_sales != 0">
                            <span ><i class="fas fa-star" style="color:gold;"></i></span> 
                            <span>{{product.rating}}</span>
                            <span>|</span>
                            <span>{{product.total_sales}} terjual</span>
                        </div>
                        <div style="margin-top:8px" v-if="!getSelectedFinalVariant" >
                            <div class="price-product-detail-product">
                                <span v-if="product.discount_max && product.discount_max != '0%' && product.price_normal != 'Rp0' " id="harga_asli" class="price-normal-product-detail-product">
                                {{product.price_normal}}
                                </span>
                                <span class="price-percent-product-detail-product" v-if="product.discount_max && product.discount_max != '0%'"  id="diskon">
                                    {{isFlashsale ? flashSaleAktif.discount_max : product.discount_max }} 
                                </span>
                            </div>
                            <span class="price-discount-product-detail-product">{{product.price_range}}</span>
                            <div class="info-detail-red" v-if="product.status_voucher != 'Rp0'">Produk berlaku untuk tambahan diskon {{ product.status_voucher }}</div>
                        </div>
                        <div style="margin-top:8px" v-if="getSelectedFinalVariant"  >
                            <div class="price-product-detail-product">
                                <span  v-if="getSelectedFinalVariant.valid_discount && getSelectedFinalVariant.valid_discount != '0%'" id="harga_asli" class="price-normal-product-detail-product">
                                    {{getSelectedFinalVariant.r_uni_product_variant_prices.ho_normal_rp}}
                                </span>
                                <span class="price-percent-product-detail-product" id="diskon">
                                    {{getSelectedFinalVariant.valid_discount}}
                                </span>
                            </div>
                            <span class="price-discount-product-detail-product">{{getSelectedFinalVariant.valid_price_rp}}</span>
                            <div class="info-detail-red" v-if="getSelectedFinalVariant.valid_voucher_rp != 'Rp0'">Produk berlaku untuk tambahan diskon {{ getSelectedFinalVariant.valid_voucher_rp }}</div>
                        </div>
                        <!-- <div v-else-if="getSelectedFinalVariant && getSelectedFinalVariant.r_uni_product_flashsale " class="ol-home-product__content-item-list--item-price">
                            <span  v-if="productFlash.discount" class="ol-home-product__content-item-list--item-price-real" id="harga_asli">{{getSelectedFinalVariant.r_uni_product_variant_prices.ho_normal_rp}}</span>
                            <small class="price_barang ol-home-product__content-item-list--item-price-discount">{{productFlash.price_rp}}
                                <i v-if="productFlash.discount" class="ol-home-product__content-item-list--item-price-discount-label" id="diskon">{{productFlash.discount}} OFF</i>
                            </small>
                        </div> -->

                        <div class="ol-product-content-detail__desc-spec" style="margin-top:16px;" id="variant">
                            <div v-if="product.variants_available > 0 && !product.is_multiple_variant">
                                <span class="ol-product-content-detail__desc-spec-title"> Pilih Varian  </span>
                                <div class="ol-product-content-detail__desc-spec-text" >
                                    <div>
                                        <span v-for="item in product.r_uni_product_variants" :key="'variant-'+item.id">
                                            <a v-if="item.variant" @click="setVariant(item)" href="javascript:void(0)" :class="['item-detail-variant item-detail-variant-'+item.id+' list_var ol-product-content-detail__desc-spec-text-type',(item.stock_available == 0 ? 'disable-stock':'')]">{{item.variant}}</a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="product.variants_available > 0 && product.is_multiple_variant">
                                <span v-for="multiVariant in product.r_multiple_variant" :key="'key-multiple-'+multiVariant.text">
                                    <span class="ol-product-content-detail__desc-spec-title"> Pilih {{multiVariant.text}}</span>
                                    <div class="ol-product-content-detail__desc-spec-text" >
                                        <div>
                                            <span v-for="(item, key2) in multiVariant.detail" :key="'key-multiple-variant-'+key2">
                                                <a v-if="item" @click="setMultipleVariant($event, multiVariant.column, item)" href="javascript:void(0)" :class="['item-detail-variant item-detail-variant-'+item+' list_var ol-product-content-detail__desc-spec-text-type',(item == 0 ? 'disable-stock':'')]">{{item}}</a>
                                            </span>
                                        </div>
                                    </div>
                                </span>
                            </div>
                            <div v-if="product.colors_available > 0" style="display:none;">
                                <span class="ol-product-content-detail__desc-spec-title"> Pilih Warna  </span>
                                <div class="ol-product-content-detail__desc-spec-text" style="display:block;">
                                    <div>
                                        <span v-for="item in product.r_uni_product_variants" :key="'variant-color-'+item.id">
                                            <a v-if="item.color && item.hex_color" @click="setColor(item)" type="checkbox" :class="'item-detail-variant-color item-detail-variant-color-'+item.id+' list_warna ol-product-content__sidebar-item-list-item-color'" :id="item.color" :style="'background:linear-gradient('+item.hex_color+')'"></a>
                                        </span>
                                    </div>
                                    <p id="nama_warna" class="">Warna : {{ selectedColor? selectedColor.color:'' }}</p>
                                </div>
                            </div>
                            <div class="pre-order" v-if="productPreOrder">
                                <b>Pre-order</b> (dikemas dalam  {{ productPreOrder.duration}}  hari) 
                            </div>
                            <p v-if="getSelectedFinalVariant" style="margin-bottom:8px"> 
                                <!-- <span v-if="(getSelectedFinalVariant.r_uni_product_variant_materials).length > 0" class="info-material" style="">MATERIAL <span style="margin-left: 4px;"></span>: 
                                    <span v-for="(material,i) in getSelectedFinalVariant.r_uni_product_variant_materials" :key="'material-'+material.r_uni_gen_material.id">
                                        {{material.r_uni_gen_material.name}} 
                                        {{ (i+1) == getSelectedFinalVariant.r_uni_product_variant_materials.length ? "":","  }} 
                                    </span>
                                    <br />
                                </span> -->
                                
                                <span v-if="getSelectedFinalVariant.stock_available && getViewLink" class="info-stok" style="">Stok Online<span style="margin-left: 7px;"></span>:  <b>{{getSelectedFinalVariant.stock_available}} </b></span>
                                <span v-if="getSelectedFinalVariant.stock_retail && getViewLink" class="info-stok" style="margin-left:16px">Stok Retail <span ></span>:  <b>{{getSelectedFinalVariant.stock_retail}} </b> <br></span>
                               
                                <span v-if="getSelectedFinalVariant.dimensions_length" class="info-dimensi" style="">Dimensi<span style="margin-left: 27px;"></span>:  P : {{getSelectedFinalVariant.dimensions_length}} cm. L : {{getSelectedFinalVariant.dimensions_width}} cm. T : {{getSelectedFinalVariant.dimensions_height}} cm<br></span>
                                <span v-if="getSelectedFinalVariant.weight" class="info-berat" style="">Berat<span style="margin-left: 42px;"></span>: {{getSelectedFinalVariant.weight}} Kg</span>
                                
                            </p>
                            <!-- <br /> -->
                            <div class="ol-alert ol-alert--danger" id="wrongVar" style="display: none">
                                Silakan pilih varian terlebih dahulu
                            </div>
                            <div class="ol-alert ol-alert--danger" id="area" style="display: none">
                                Pilih lokasi pengiriman terlebih dahulu
                            </div>
                            <div  class="ol-alert ol-alert--danger" id="outOfStock" v-bind:style=" product.stock == 0 ? 'display: block;' : 'display: none;' " >
                                Barang habis atau sedang tidak tersedia
                            </div>
                            <div  class="ol-alert ol-alert--danger" id="areaUnavailable" style="display: none">
                                <span id="areaUnavailable_text"></span>
                            </div>

                            
                            <span class="ol-product-content-detail__desc-spec-title" style="margin-top:16px"> Info Pengiriman  </span>
                            <table style="margin-bottom:8px;">
                                <tr>
                                    <td style="width:100px;">Gratis Ongkir </td>
                                    <td style="width:250px;">
                                        <b>Jawa Bali</b>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Pengiriman ke </td>
                                    <td>
                                        <select id="cities" class="ol-input ol-input-select select2"  @change="checkShipping">
                                            <option value="0">Ketik yang anda cari...</option>
                                            <option v-for="(item) in citiesOverallJson" :key="item.city_id" :value="item.city_id">{{item.city_name}}</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Ongkos Kirim </td>
                                    <td>
                                        <span id="shipping-price">Rp.0</span>
                                    </td>
                                </tr>
                            </table>

                            <span class="ol-product-content-detail__desc-spec-title" style="margin-top:16px"> Kuantitas  </span>
                            <div style="border: 1px solid #A7B0BE;margin-bottom: 8px;padding: 4px;width: 120px;border-radius: 8px;text-align: center;font-size: 14px;">
                                <button type="button"  @click="qtyMinus()" class="qtyplus" style="font-weight: 700;font-size: 18px;"> - </button>
                                <input type='number' name='quantity' :value='quantity' class='qty' id="quantity" style="width:60px" min="1"/>
                                <button type="button"  @click="qtyPlus()" class="qtyplus" style="font-weight: 700;font-size: 18px;"> + </button>
                            </div>                            

                            <div class="ol-product-content-detail__action">
                                <a style="padding:8px 12px 6px 12px" @click="toSpesifikasi()" type="button" class="ol-btn ol-btn-wishlist">
                                    <i style="font-size:14px;margin-right:0px;" class="fa fa-info ol-fa"></i>
                                </a>
                                <button v-show="!loading.wishlist" style="padding:8px 8px;" id="btn-wishlist" type="button" @click="toWishlist" :class="'ol-btn ol-btn-wishlist '+isOnWishlistClass">
                                    <i style="font-size:14px;margin-right:0px;" class="fas fa-heart ol-fa"></i>
                                </button>
                                <button v-show="loading.wishlist" style="padding:8px 8px;" type="button" class="ol-btn ol-btn-wishlist">
                                    <i class="fas fa-spinner fa-pulse ol-fa" style="'pointer-events: none;font-size:14px;margin-right:0px;"></i>
                                </button>

                                <!-- <button v-show="!loading.buy" type="button" @click="beliLangsung()" :class="product.stock == 0 ? 'ol-btn ol-btn-disable':'ol-btn' " style="color: #C13B37;background: rgba(193, 59, 55, 0.1);" >Beli Sekarang </button>
                                <button v-show="loading.buy" type="button" class="ol-btn" style="pointer-events: none;color: #C13B37;background: rgba(193, 59, 55, 0.1);"> <i class="fas fa-spinner fa-pulse ol-fa"></i>&nbsp;Beli Sekarang </button> -->

                                <button v-show="!loading.tocart" type="button" @click="toCart()" id="button_cart" :class="product.stock == 0 ? 'ol-btn ol-btn-disable':'ol-btn' " > +&nbsp;Keranjang </button>
                                <button v-show="loading.tocart" type="button" class="ol-btn" style="pointer-events: none;"> <i class="fas fa-spinner fa-pulse ol-fa"></i>&nbsp;Keranjang </button>

                                <!-- <button v-show="loading.wishlist" type="button" style="pointer-events: none;" :class="'ol-btn ol-btn-wishlist'+isOnWishlistClass"> <i class="fas fa-spinner fa-pulse ol-fa"></i> Favorit </button> -->
                                <!-- <button  type="button" @click="showPopup()" class="ol-btn ol-btn-wishlist"> <i class="fas fa-map-pin ol-fa"></i> {{location ? location.city_name : 'Pilih Lokasi Pengiriman'}} </button> -->
                                <!-- <button  @click="showPopup2()" type="button"  class="ol-btn ol-btn-wishlist"> <i class="fas fa-comment-alt ol-fa"></i></button> -->
                            </div>

                        </div>
                        <div v-if="this.product.price_range_raft != 'Rp0'" style="margin-top:16px;">
                            <div  @click="raftProduct()" style="width: 310px; height: 62px; padding-left: 20px; padding-right: 20px; padding-top: 8px; padding-bottom: 8px; background: #F6F7F9; border-radius: 8px; justify-content: flex-start; align-items: center; gap: 20px; display: inline-flex; ">
                                <div style="width: 24px; height: 24px; justify-content: center; align-items: center; display: flex">
                                    <div style="width: 24px; height: 24px; position: relative">
                                        <i class="fa fa-info-circle" style="width:100%; height:100%;"></i>
                                    </div>
                                </div>
                                <div style="width: 230px; flex-direction: column; justify-content: center; align-items: flex-start; gap: 4px; display: inline-flex">
                                    <div style="align-self: stretch; color: #141414; font-size: 16px; font-weight: 700; line-height: 22.40px; word-wrap: break-word">Informasi Jasa Perakitan</div>
                                    <div style="justify-content: flex-start; align-items: center; gap: 8px; display: inline-flex">
                                    <div>
                                        <span style="color: #4E6CB1; font-size: 14px; font-weight: 700; line-height: 18.20px; word-wrap: break-word">
                                        + {{product.price_range_raft}}</span>
                                        <span style="color: #A7B0BE; font-size: 14px; font-weight: 500; line-height: 18.20px; word-wrap: break-word">/barang </span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="ol-product-content-detail__desc-share" style="margin-top:16px; margin-bottom:16px;">
                            <span> Share</span>
                            <div>
                                <a href="javascript:void(0)" @click="fb_share(product.name,'https://link.morefurniture.id/product/'+product.slug)" class="ol-product-content-detail__desc-share-link" target="_blank" title="Facebook">
                                    <img :src="require('@/assets/images/social-media/facebook-blue.png')"  width="24" alt="Facebook">
                                </a>
                                <a :href="'https://api.whatsapp.com/send/?phone&text='+product.name+'+-++-+https://link.morefurniture.id/product/'+product.slug" class="ol-product-content-detail__desc-share-link" target="_blank" title="Whatsapp">
                                    <img :src="require('@/assets/images/social-media/whatsapp-blue.png')"  width="24" alt="Whatsapp">
                                </a>
                                <a href="javascript:void(0)" @click="copyLinkWa()" class="ol-product-content-detail__desc-share-link" title="Copy Link">
                                    <img :src="require('@/assets/images/social-media/link-blue.png')"  width="24" alt="copy link" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="product.r_uni_product_combo.length >= 1" class="ol-product-content ol-product-related add-fix kombo-hemat-box">
                    <h2 class="ol-section-title" style="margin-top:8px;margin-bottom:8px">
                        <span>Beli Kombo Hemat</span>
                    </h2>
                    <div class="row no-gutters"  style="margin-bottom:8px;">
                        <div class="col-md-12 col-lg-4" >
                            <div class="row ">
                                <div class="col-5">
                                    <div class="kombo-hemat-card">
                                        <div v-if="product.r_uni_product_combo[0].r_uni_product_variants_main.r_uni_product_image">
                                            <img :src="product.r_uni_product_combo[0].r_uni_product_variants_main.r_uni_product_image.image_url" class="kombo-hemat-image">
                                        </div>
                                        <div v-else>
                                            <img src="https://asset.morefurniture.id/images/noimage.png">
                                        </div>
                                        
                                        <div class="kombo-hemat-box-price">
                                            <!-- <span>{{product.r_uni_product_combo[0].r_uni_product_variants_main.name_short}}</span><br> -->
                                            <span class="kombo-hemat-highlight">{{product.r_uni_product_combo[0].r_uni_product_variants_main.valid_price_rp}}</span>
                                        </div>
                                        <select v-if="listComboVariantPrimary.length > 1" v-model="selectedComboPrimary" style="padding:8px;width:100%;background:#f5f5f5;">
                                            <option v-for="list in listComboVariantPrimary" :value="list">{{ list }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-2" style="display: table;">
                                    <span class="kombo-hemat-plus">+</span>
                                </div>
                                <div class="col-5">
                                    
                                    <div class="kombo-hemat-card">
                                        <a :href="`/product/${product.r_uni_product_combo[0].r_uni_products.slug}`">
                                            <div v-if="product.r_uni_product_combo[0].r_uni_product_variants.r_uni_product_image">
                                                <img :src="product.r_uni_product_combo[0].r_uni_product_variants.r_uni_product_image.image_url" class="kombo-hemat-image">
                                            </div>
                                            <div v-else>
                                                <img src="https://asset.morefurniture.id/images/noimage.png">
                                            </div>
                                            
                                            <div class="kombo-hemat-box-price">
                                                <!-- <span>{{product.r_uni_product_combo[0].r_uni_product_variants.name_short}}</span><br> -->
                                                <span class="kombo-hemat-highlight">{{product.r_uni_product_combo[0].combo_price_rp}}</span>
                                            </div>
                                        </a>
                                        
                                        <select v-if="listComboVariantSecondary.length > 1" v-model="selectedComboSecondary" style="padding:8px;width:100%;background:#f5f5f5;">
                                            <option v-for="list in listComboVariantSecondary" :value="list">{{ list }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 col-lg-4">
                            <div class="row no-gutters kombo-hemat-box-button" >
                                <div class="col-6">
                                    <span>Total : {{product.r_uni_product_combo[0].combo_price_total_rp}}</span><br>
                                    <span class="kombo-hemat-highlight" v-if="product.r_uni_product_combo[0].combo_price_hemat_rp != 'Rp.0'">Hemat : {{product.r_uni_product_combo[0].combo_price_hemat_rp}}</span>
                                </div>
                                <div class="col-6" style="text-align:right;">
                                    <button type="button" class="ol-btn" @click="toCartCombo(product.r_uni_product_combo[0])" style="width:100%"> +&nbsp;Masukkan Keranjang </button>
                                    <!-- <div class="ol-alert ol-alert--danger" id="outOfStockCombo" style="display: none; padding:8px;margin-top:8px;text-align:center;">
                                        Salah satu barang habis atau sedang tidak tersedia
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <input type="checkbox" class="read-combo-state" id="post-2" />
                    <div class="read-combo-wrap">
                        <div class="read-combo-target">
                            <div v-if="listComboVariantSecondary.length > 1" class="row no-gutters" v-for="(combo,index) in product.r_uni_product_combo" :key="index" style="margin-bottom:8px;">
                                <div class="col-md-12 col-lg-4" v-if="index > 0">
                                    <div class="row ">
                                        <div class="col-5">
                                            <div class="kombo-hemat-card">
                                                <div v-if="combo.r_uni_product_variants_main.r_uni_product_image">
                                                    <img :src="combo.r_uni_product_variants_main.r_uni_product_image.image_url" class="kombo-hemat-image">
                                                </div>
                                                <div v-else>
                                                    <img src="https://asset.morefurniture.id/images/noimage.png" class="kombo-hemat-image">
                                                </div>
                                                
                                                <div class="kombo-hemat-box-price">
                                                    <!-- <span>{{combo.r_uni_product_variants_main.name_short}}</span><br> -->
                                                    <span class="kombo-hemat-highlight">{{combo.r_uni_product_variants_main.valid_price_rp}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-2" style="display: table;">
                                            <span class="kombo-hemat-plus">+</span>
                                        </div>
                                        <div class="col-5">
                                            
                                            <div class="kombo-hemat-card">
                                                <a :href="`/product/${combo.r_uni_products.slug}`">
                                                    <div v-if="combo.r_uni_product_variants.r_uni_product_image">
                                                        <img :src="combo.r_uni_product_variants.r_uni_product_image.image_url" class="kombo-hemat-image">
                                                    </div>
                                                    <div v-else>
                                                        <img src="https://asset.morefurniture.id/images/noimage.png" class="kombo-hemat-image">
                                                    </div>
                                                    
                                                    <div class="kombo-hemat-box-price">
                                                        <!-- <span>{{combo.r_uni_product_variants.name_short}}</span><br> -->
                                                        <span class="kombo-hemat-highlight">{{combo.combo_price_rp}}</span>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 col-lg-4" v-if="index > 0">
                                    <div class="row no-gutters kombo-hemat-box-button" >
                                        <div class="col-6">
                                            <span>Total : {{combo.combo_price_total_rp}}</span><br>
                                            <span class="kombo-hemat-highlight" v-if="combo.combo_price_hemat_rp != 'Rp.0'">Hemat : {{combo.combo_price_hemat_rp}}</span>
                                        </div>
                                        <div class="col-6" style="text-align:right;">
                                            <button type="button" class="ol-btn" @click="toCartCombo(combo)" style="width:100%"> +&nbsp;Masukkan Keranjang </button>
                                            <!-- <div class="ol-alert ol-alert--danger" id="outOfStockCombo" style="display: none; padding:8px;margin-top:8px;text-align:center;">
                                                Salah satu barang habis atau sedang tidak tersedia
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <label v-if="listComboVariantSecondary.length > 1" for="post-2" class="read-combo-trigger ol-btn ol-btn-wishlist"></label>
                </div>
                <ul v-if="voucher.length > 0" class="card-vouchers">
                    <li class="card-voucher" v-for="voucher in voucher" :key="'voucher-'+voucher.id">
                        <div class="main">
                            <div class="content-voucher">
                                <span class="discount-container">
                                    <img class="discount-img" :src="require('@/assets/images/discount.png')" width="20">
                                    Diskon &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span v-if="voucher.show_on === 1">{{ voucher.voucher_rp }}</span>
                                    <span v-else-if="voucher.show_on === 3">{{ voucher.max_discount_price_rp }}</span>
                                    <span v-else-if="voucher.show_on === 4">{{ voucher.percentage }}%</span>
                                </span>
                                <div class="break-1"></div>
                                <p v-if="voucher.min_purchase_rp != 'Rp0'">Min. Transaksi <b>{{ voucher.min_purchase_rp }}</b></p>
                                <p v-else>Tanpa Min. Transaksi</p>
                                <p>Hingga <b>{{ voucher.expired }}</b></p>
                            </div>
                        </div>
                        <div class="copy-button">
                            <button @click="copyNumber(voucher.code)" class="copybtn">Salin</button>
                        </div>
                    </li>
                </ul>
                <div class="ol-product-content ol-product-content-detail" id="spesifikasi">
                    <div class="overview-tab">
                        <a id="deskripsi-btn-tab" class="col tab-btn" @click="changeTab(1)"> Foto Detail</a>
                        <a id="informasi-btn-tab" class="col tab-btn" @click="changeTab(3)"> Spesifikasi</a>
                        <a v-if="reviews.length > 0" id="ulasan-btn-tab" class="col tab-btn" @click="changeTab(2)"> Ulasan</a> 
                    </div>
                    <div class="ol-product-content-detail__list" id="deskripsiInwrap" style=" margin-top: 0;">
                        <div id="detail1" class="g-3 ol-product-content-detail__list-item ol-product-content-detail__list-item--desc show">
                            <div v-if="product.sku_parent != 'BUNDLING'">
                                <input type="checkbox" class="read-more-state" id="post-1" />
                                <div class="read-more-wrap">
                                    <div class="g-3 row pt-3" >
                                        <div class="col-lg-6 mt-3" v-for="image in product.r_uni_product_infos" :key="'image-info-'+image.id">
                                            <img :srcset="image.image_url" :alt="image.alt_text">
                                        </div>
                                    </div>
                                    <span class="read-more-target">
                                        <div class="g-3 row pt-3">
                                            <div class="col-lg-6 mt-3" v-for="image in product.r_uni_product_details" :key="'image-detail-'+image.id">
                                                <img :srcset="image.image_url" :alt="image.alt_text">
                                            </div>
                                        </div>
                                    </span>
                                </div>
                                <label v-if="product.r_uni_product_details.length > 1" for="post-1" class="read-more-trigger ol-btn ol-btn-wishlist"></label>
                            </div>
                            
                            <div class="g-3 row no-gutters pt-3" v-if="product.sku_parent == 'BUNDLING'">
                                <div class="col-lg-6 mt-3" v-for="image_bundling in product.r_uni_product_image_bundling" :key="'image-bundling-'+image_bundling.uni_products_id"  v-if="image_bundling.type == 'PRODUK-INFO'">
                                    <img  :srcset="image_bundling.image_url" :alt="image_bundling.alt_text">
                                </div>
                            </div>
                            <br>
                            <iframe v-for="video in product.r_uni_product_videos" :key="'video-yt-'+video.id" class="iframe-youtube" :src="video.youtube_url" allow="fullscreen;"> </iframe>
                        </div>
                    </div>
                    <div class="review-tab" id="informationInWrap">
                        <p class="ol-product-content-detail__list-item-text" v-html="product.description" style="text-align:justify"></p>
                        <div class="row no-gutters">
                            <div class="col-md-6">
                                <table class="ol-product-content-detail-tbl">
                                    <thead>
                                        <tr>
                                        <th class="ol-product-content-detail-tbl-title" colspan="2">Deskripsi Produk</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="ol-product-content-detail-tbl-content">Merek</td>
                                            <td>
                                                {{product.brand}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="ol-product-content-detail-tbl-content">Perakitan</td>
                                            <td>
                                                Perlu Dirakit
                                            </td>
                                        </tr>
                                        <tr v-if="product.r_uni_product_variants.length > 1">
                                            <td>Varian</td>
                                            <td>
                                                <template v-for="variant in product.r_uni_product_variants">
                                                    {{ variant.variant }},
                                                </template>
                                            </td>
                                        </tr>
                                        <tr v-if="product.seo_1">
                                            <td>Bahan</td>
                                            <td>
                                                {{product.seo_1}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="ol-product-content-detail-tbl" v-if="product.sku_parent != 'BUNDLING'">
                                    <thead>
                                        <tr>
                                        <th class="ol-product-content-detail-tbl-title" colspan="2">Dimensi (P x L x T) & Stok</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="variant in product.r_uni_product_variants" >
                                            <tr :key="'dimensi1-'+variant.id">
                                                <td colspan="2">{{ variant.name_long }}</td>
                                            </tr>
                                            <tr v-if="variant.dimensions_length" >
                                                <td  class="ol-product-content-detail-tbl-content">
                                                    &nbsp;&nbsp;&nbsp;Ukuran Barang
                                                </td>
                                                <td>
                                                    {{ variant.dimensions_length }}cm x {{ variant.dimensions_width }}cm x {{ variant.dimensions_height }}cm
                                                </td>
                                            </tr>
                                            <tr v-if="variant.weight">
                                                <td  class="ol-product-content-detail-tbl-content">
                                                    &nbsp;&nbsp;&nbsp;Berat
                                                </td>
                                                <td>
                                                    {{ variant.weight }} Kg
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                                <table class="ol-product-content-detail-tbl" v-if="product.sku_parent == 'BUNDLING'">
                                    <thead>
                                        <tr>
                                        <th class="ol-product-content-detail-tbl-title" colspan="2">Dimensi (P x L x T)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="variant in product.r_uni_product_description_bundling">
                                            <tr :key="'bundling1-'+variant.id">
                                                <td colspan="2"><router-link :to="'/product/'+variant.slug" class="text-normal-link"><b>{{ variant.name }}</b></router-link></td>
                                            </tr>
                                            <tr v-if="variant.dimensions_length" :key="'bundling2-'+variant.id">
                                                <td  class="ol-product-content-detail-tbl-content">
                                                    &nbsp;&nbsp;&nbsp;Ukuran Barang
                                                </td>
                                                <td>
                                                    {{ variant.dimensions_length }}cm x {{ variant.dimensions_width }}cm x {{ variant.dimensions_height }}cm
                                                </td>
                                            </tr>
                                            <tr v-if="variant.weight" :key="'bundling3-'+variant.id">
                                                <td  class="ol-product-content-detail-tbl-content">
                                                    &nbsp;&nbsp;&nbsp;Berat
                                                </td>
                                                <td>
                                                    {{ variant.weight }} Kg
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>

                                <table v-if="product.r_uni_product_superiorities.length > 1 & product.sku_parent != 'BUNDLING'" class="ol-product-content-detail-tbl">
                                    <thead>
                                        <tr>
                                        <th class="ol-product-content-detail-tbl-title" colspan="2">Keunggulan</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="superiorities in product.r_uni_product_superiorities">
                                            <tr :key="'superiorities-'+superiorities.id">
                                                <td>- {{ superiorities.name }}</td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-md-6">
                                <table class="ol-product-content-detail-tbl">
                                    <thead>
                                        <tr>
                                        <th class="ol-product-content-detail-tbl-title" colspan="2">Garansi</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="ol-product-content-detail-tbl-content">Tipe Garansi</td>
                                            <td>
                                                Ganti Barang / Refund*
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Durasi Waktu</td>
                                            <td>
                                                2x24 Jam
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Syarat Klaim</td>
                                            <td>
                                                Foto / Video Unboxing
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="ol-product-content-detail-tbl">
                                    <thead>
                                        <tr>
                                        <th class="ol-product-content-detail-tbl-title" colspan="2">Pengiriman</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="ol-product-content-detail-tbl-content">Gratis Pengiriman</td>
                                            <td>
                                                Jawa & Bali
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>Estimasi Pengiriman</td>
                                            <td>
                                                7 - 14 Hari Kerja
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Cek Biaya Kirim</td>
                                            <td>
                                                <a class="text-normal-link" @click="chatDelivery()" target="_blank"><u>Tanya Ongkir (Luar Jawa  & Bali)</u></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="ol-product-content-detail-tbl">
                                    <thead>
                                        <tr>
                                        <th class="ol-product-content-detail-tbl-title" colspan="2">Lainnya</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="ol-product-content-detail-tbl-content">Kondisi Produk</td>
                                            <td>
                                                Tidak Terakit
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Cara Perakitan</td>
                                            <td>
                                                <span v-if="product.r_uni_product_raft_guides < 1">Disertakan</span>
                                                <div v-for="item in product.r_uni_product_raft_guides" :key="'raft-'+item.id">
                                                    <a  class="text-normal-link" target="_blank" :href="item.image_url" ><u>{{ item.name }}</u></a>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="review-tab" id="reviewInWrap">
                        <ReviewList/>
                    </div>
                </div>
            </div>
            <div class="break-24"></div>
            <div class="ol-wrapper ol-wrapper--large" id="rekomendasi">
                <div class="ol-product-content ol-product-related add-fix">
                    <h2 class="ol-section-title">
                        <span>Rekomendasi produk</span>
                            <i></i>
                    </h2>
                    <ProductLoader wrapclass="ol-product-content__center-list" :page="2" :qty="5" v-if="loading.related"/>
                    <!-- <ProductList :products="related" v-if="!loading.related" />  -->
                    <CarouselProduct :products="related" v-if="!loading.related" carouselKey="related" />
                </div>
            </div>
            <div class="break-24"></div>
            <div class="break-24"></div>
            <!-- <div v-if="partner.length > 0" class="ol-wrapper ol-wrapper--large">
                <div class="ol-product-content ol-product-related add-fix">
                    <h2 class="ol-section-title">
                        <span>Produk </span>
                            <i></i>
                    </h2>
                    <ProductLoader wrapclass="ol-product-content__center-list" :page="2" :qty="5" v-if="loading.partner"/>
                    <ProductList :products="partner" v-if="!loading.partner" /> 
                </div>
            </div> -->
            <div class="ol-wrapper ol-wrapper--large">
                <div class="ol-product-content ol-product-related add-fix">
                    <h2 class="ol-section-title">
                        <span>Dari Pencarianmu</span>
                            <i></i>
                    </h2>
                    <ProductLoader wrapclass="ol-product-content__center-list" :page="2" :qty="5" v-if="loading.lastSeen"/>
                    <!-- <ProductList :products="lastSeen" v-if="!loading.lastSeen" />  -->
                    <CarouselProduct :products="lastSeen" v-if="!loading.lastSeen" carouselKey="lastseen" />
                </div>
            </div>
            <div class="break-24"></div>
        </section>
        
		<Popup title="Pilih Lokasi Pengiriman">
            <div class="ol-input-box ol-input-box--full">
                <label class="ol-input-label">Kota/Kabupaten</label>
                
            </div>
            <div class="ol-input-box ol-input-box--half">
                <button type="button" name="" @click="saveLocation()" class="ol-btn">Simpan</button>
            </div>
        </Popup>
        <CartPopup title="Berhasil Ditambahkan" id="checkout" maxWidth="80%">
            <div style="background-color: rgb(234, 237, 241);padding: 8px;border-radius: 8px;margin: 0 16px 0 16px;margin-top: 4px;">
                <div class="row no-gutters" >
                    <div class="col-lg-10 col-md-12">
                        <span class="ol-home-product__content-item-list--item-title" style="color: #3c4043;font-size: 14px;height: auto;">
                            {{productName}}
                        </span>
                    </div>
                    <div class="col-lg-2 col-md-12" style="text-align:center;">
                        <!-- <button type="button" class="ol-btn" @click="toViewCart()" style="text-align:center;margin-top:4px;">Lihat Keranjang</button> -->
                        <a class="ol-btn" href="/cart" style="text-align:center;margin-top:4px;">Lihat Keranjang</a>
                    </div>
                </div>
            </div>
            
            <div style="border: 1px solid #f5f5f5;width:100%;margin-top:8px;"></div>
            
            <div v-if="related.length > 0" >
                <div class="ol-product-content ol-product-related add-fix" style="padding-top:0px;">
                    <h2 class="ol-section-title" style="margin-left:15px;margin-bottom:0px;">
                        <span style="font-weight: 500; font-size:16px; color:#212121;">Rekomendasi Produk</span>
                            <i></i>
                    </h2>
                    <ProductLoader wrapclass="ol-product-content__center-list" :page="2" :qty="5" v-if="loading.related"/>
                    <ProductList :products="relatedFive" v-if="!loading.related" />
                </div>
            </div>
        </CartPopup>
        <Popup title="Kirim pesan" id="pesanan">
            <Alert v-show="input.message !=null" :type="input.type" :message="input.message"/>
            <div class="ol-input-box ol-input-box--full">
                <!-- <label class="ol-input-label">Chat</label> -->
                <textarea class="ol-input"  placeholder="Enter text here..." v-model="input.pesan" rows="4" style="height:unset"></textarea>
            </div>
            <div class="ol-input-box ol-input-box--half">
                <button v-show="input.loading" type="button" style="pointer-events: none;" class="ol-btn ol-btn-wishlist"> <i class="fas fa-spinner fa-pulse ol-fa"></i> Kirim</button>
                <button v-show="!input.loading" type="button" name="" @click="chatProduct()" class="ol-btn"><i class="fas fa-paper-plane  ol-fa"></i>  Kirim</button>
            </div>
        </Popup>
        <Popup :title="productName" id="youtubeCatalog" >
            <iframe :key="'video-catalog-'+selectedVideoCatalog.id" id="playYoutube" class="iframe-youtube-catalog" allow="fullscreen;" :src="selectedVideoCatalog.url" width="100%"> </iframe>
        </Popup>
        <Popup title="Informasi Jasa Rakit" id="rakit">
            
            <div class="ol-input-box ol-input-box--full">
                <div><span style="font-size:16px;"><b>{{ this.product.price_range_raft }}</b></span> / barang</div>
                untuk Produk {{ this.product.name }}
                <hr style="border: 1px solid #D2D2D2;margin-top: 16px;margin-bottom: 16px;">
                <div class="rakit-info">
                    <div class="rakit-info-label">
                        <i class="fa fa-map-pin"></i>
                    </div>
                    <div class="rakit-info-description">
                        hanya tersedia untuk area <b>Surabaya, Gresik, Sidoarjo, Jakarta, Bogor, Depok, Tangerang, Bekasi, Karawang, Semarang</b>
                    </div>
                </div>
                <div class="rakit-info">
                    <div class="rakit-info-label">
                        <i class="fa fa-times-circle"></i>
                    </div>
                    <div class="rakit-info-description">
                        Pesanan jasa rakit tidak bisa dibatalkan.
                    </div>
                </div>
                <div class="rakit-info">
                    <div class="rakit-info-label">
                        <i class="fa fa-wrench"></i>
                    </div>
                    <div class="rakit-info-description">
                        <b>Pengiriman dan Perakitan</b> Dilakukan di hari dan oleh tim yang berbeda
                    </div>
                </div>
                <div class="rakit-info">
                    <div class="rakit-info-label">
                        <i class="fa fa-phone"></i>
                    </div>
                    <div class="rakit-info-description">
                        <b>Mohon melakukan konfirmasi penerimaan barang</b>. <b>Estimasi 2 Hari kerja</b> setelah konfirmasi dilakukan, tim perakit akan menghubungi Anda dan menjadwalkan kedatangan.
                    </div>
                </div>
                <button type="button" name="" class="ol-btn" @click="chatRaft(product.name)">Hubungi Kami</button>

            </div>
        </Popup>
    </BaseLayout>
</template>

<script>
import {mapActions,mapGetters} from "vuex";
import BaseLayout from "@/components/landing/BaseLayout.vue";
import DetailLoader from '@/components/loading/product/Detail.vue'
import ProductList from "@/components/product/ProductList.vue"
import ReviewList from "@/components/product/ReviewList.vue"
import ProductLoader from '@/components/loading/product/Content.vue'
// import ReviewLoader from '@/components/loading/product/Review.vue'
import SkeletonLoader from '@/components/loading/skeletonLoader'
import CarouselProduct from '@/components/home/CarouselProduct'
import Popup from '@/components/popup/Popup.vue'
import CartPopup from '@/components/popup/CartPopup.vue'
import globalUrl from '@/util/globallUrl'
import select2 from '@/lib/select2'
import  moment from '@/lib/moment';
import apiurl from '@/util/apiurl';
const PRODUCT = apiurl.WS_PRODUCT;
const UNI = globalUrl.WS_UNI;
const BASE = process.env.VUE_APP_BASE_API_URL+"/";

export default {
    name: "ProductDetail",
    metaInfo() {
        return{
            meta: [
                // { property: 'title', content:this.product.name},
                { name: 'title', content:this.product?.meta_title},
                { name: 'description', content: this.product?.meta_desc ? this.product.meta_desc : ''},
                { name: 'keywords', content: this.product?.keyword ? this.product.keyword : ''},
                { name: 'robots', content: 'index,follow'},
                { property: 'og:title', content: this.product?.meta_title},
                { property: 'og:url', content: window.location.origin+this.$route.fullPath}, 
                { property: 'og:description', content: this.product?.meta_desc ? this.product.meta_desc : ''},
                // { property: 'og:image', content: this.product.r_uni_product_images[0].image_url},
                { property: 'og:site_name', content: 'More Furniture'},
                { property: 'og:type', content: 'website'}
            ],
            link: [
                {rel: 'canonical', href: window.location.origin+this.$route.fullPath}
            ]
        }
    },
    components: {
        BaseLayout,
        ProductList,
        ProductLoader,
        DetailLoader,
        SkeletonLoader,
        CarouselProduct,
        // ReviewLoader,
		Popup,
		CartPopup,
        ReviewList,
    },
    data(){
        return {
            loading:{
                tocart:false,
                buy:false,
                detail:false,
                related:false,
                lastSeen:false,
                inspiration:false,
                partner:false,
                voucher: false,
                rating:false,
                review:false,
                wishlist:false
            },
            input:{
                loading:false,
                pesan:'',
                type:null,
                message:null
            },
            isFlashsale:false,
            tempLocations:[],
            countdown: 0,
            stopCountdown:null, // 5min
            jsonld:null,
            selectedVideoCatalog: {
                url: "",
                id: ""
            },
            showingPopUpCheckout: false,
            selectedVariant1: null,
            selectedVariant2: null,
            selectedComboPrimary:'',
            selectedComboSecondary:'',
            quantity:1
        }
    },
    computed:{
        ...mapGetters('auth', ['location','authenticated']),
        ...mapGetters("product/detail",["product","related","partner","voucher","selectedVariant","selectedColor","rating","relatedInspiration","productFlash","collection","getSelectedFinalVariant","productFree","productPreOrder","getViewLink","reviews"]),
        ...mapGetters("home",["flashSaleAktif"]),
        ...mapGetters('profile/product', ['lastSeen']),
        ...mapGetters('profile/wishlist', {wishlist: 'data', wishlistGuest: 'data_guest'}),
        ...mapGetters("profile/address", ["citiesOverallJson"]),  
        productName() {
            if(this.getSelectedFinalVariant) return this.getSelectedFinalVariant.name_long;
            return this.product.name;
        },
        productVideosCatalog() {
            return _.filter(this.product.r_uni_product_videos, {type: 'Katalog'})
        },
        formatedCountdown() {
            var v = moment.utc(moment.duration(this.countdown,"s").asMilliseconds()).format("HH:mm:ss")
            return v;
        },
        relatedFive() {
            return _.take(this.related, 5);
        },
        isOnWishlistClass() {
            let productId = this.product.id;
            let _class = '';
            _.forEach(this.wishlist, function(val) {
                if(val.r_uni_products?.id == productId) {
                    _class = 'fav';
                    return;
                }
            });
            if(_class == '') {
                _.forEach(this.wishlistGuest, function(val) {
                    if(val.id == productId) {
                        _class = 'fav';
                        return;
                    }
                });
            }
            return _class;
        },
        listComboVariantPrimary() {
            this.selectedComboPrimary = this.product.r_uni_product_combo[0].v1_name;
            const groupedData = this.groupBy(this.product.r_uni_product_combo, 'v1_name');
            const v1_name = Object.keys(groupedData);
            
            return v1_name;
        },
        listComboVariantSecondary() {
            this.selectedComboSecondary = this.product.r_uni_product_combo[0].v2_name;
            const groupedData = this.groupBy(this.product.r_uni_product_combo, 'v2_name');
            const v2_name = Object.keys(groupedData);
            console.log(v2_name);
            return v2_name;
        }
    },
    methods:{
        ...mapActions("chats", ["fetchData",'saveChat']),
        ...mapActions("product/detail",["fetchProduct","fetchRelated","fetchPartner","fetchVoucher","fetchRelatedInspiration","fetchReviews","fetchProductFlash","fetchProductFree","fetchProductPreOrder","addViewLink"]),
        ...mapActions("transaction/cart",["checkStock","save","cartFbPixel","checkLocation"]),
        ...mapActions('home',['getFlashSale']),
        ...mapActions('profile/product', ['saveProductHistory','fetchLastSeen']),
        ...mapActions('profile/wishlist', {fetchDataWishlist: 'fetchData'}),
        ...mapActions("profile/address", ["getCitiesOverallJson"]),
        searchCategory(input){
            // Convert to lowercase
            let lowerCased = input.toLowerCase();
    
            // Replace spaces with hyphens
            let hyphenated = lowerCased.replace(/\s+/g, '-');

            return "https://morefurniture.id/category/"+hyphenated;
        },
        setVariant(variant, isFromMulti = false){
            this.$store.commit("product/detail/setSelectedVariant", variant);
            if(!isFromMulti) {
                $("a.item-detail-variant").removeClass("active");
                $("a.item-detail-variant-"+variant.id).addClass("active");
            }
            $(".item-detail-images#detail-"+variant.r_uni_product_image.id).trigger("click");
        },
        setMultipleVariant(e, column, value) {
            if(column == 'variant_1_name') {
                this.selectedVariant1 = value;
            }
            else {
                this.selectedVariant2 = value;
            }
            const $el = e.target;
            const $siblings = $el.parentNode.parentNode.childNodes;
            for (let i = 0; i < $siblings.length; i++) {
                
                $siblings[i].childNodes[0].classList.remove('active');
            }
            const filter = {variant: this.selectedVariant1, color: this.selectedVariant2};
            const variants = this.product.r_uni_product_variants;
            const selectedVariant = _.find(variants, filter);
            
            $el.classList.toggle('active');
            this.setVariant(selectedVariant, true);
        },
        setColor(variantColor){
            this.$store.commit("product/detail/setSelectedColor", variantColor);

            $("a.item-detail-variant-color").removeClass("active");
            $("a.item-detail-variant-color-"+variantColor.id).addClass("active");
            $(".item-detail-images#detail-"+variantColor.r_uni_product_image.id).trigger("click");   
        },
        chatProduct(){
            if(this.input.pesan != ''){
                this.input.loading = true;
                let param ={
                    text:this.input.pesan,
                    uni_products_id:this.product.id
                };
                this.saveChat(param).then(result => {
                    this.input.loading = false;
                    this.input.pesan ='';
                    this.input.type = 'success';
                    this.input.message = 'Pesan berhasil dikirim';
                    

                }).catch(error =>{
                    this.input.loading = false;
                    this.input.type = 'danger';
                    this.input.message = 'Pesan gagal dikirim, coba lagi nanti';
                });
            }
        },
        async toSpesifikasi(){
            this.scrollToHash("spesifikasi");
        },
        async chatRaft(product){
			let texts = "Hai MORE saya ingin bertanya Jasa Rakit *Produk : "+product+"*";
            let sendWa ={
                phone:process.env.VUE_APP_WA_CS,
                text:texts
            }
            window.open('https://api.whatsapp.com/send?'+$.param(sendWa), '_blank');
        },
        async toCart(){
            this.scrollToHash("variant");
            this.loading.tocart = true;
            
            const _variant = {...this.getSelectedFinalVariant, is_get_combo: 0, r_uni_product_get_combo:null, id_combo:null, quantity: this.quantity};
            if(!this.getSelectedFinalVariant){
                $(".ol-alert#wrongVar").stop().slideDown("fast").delay(3000).slideUp("fast");
                this.loading.tocart = false;
                return false;
            }

            //Ceck Pilih Lokasi
            // if(!this.location){
            //     $(".ol-alert#area").stop().slideDown("fast").delay(3000).slideUp("fast");
            //     this.loading.tocart = false;
            //     this.showPopup(true);
            //     return false;
            // }

            // let resultL = await this.checkLocation({sku_group: this.getSelectedFinalVariant.sku_group, uni_product_variants_id: this.getSelectedFinalVariant.id, city_id: this.location.city_id});
            // if(!resultL.isValid){
            //     $("#areaUnavailable_text").html(resultL.message);
            //     $(".ol-alert#areaUnavailable").stop().slideDown("fast").delay(3000).slideUp("fast");
            //     this.loading.tocart = false;
            //     return false;
            // }
                
            this.$store.commit('transaction/cart/plus2Cart', _variant); //Tambah Ke Store
            let result = await this.checkStock(_variant);
            this.loading.tocart = false;
            if(result.success){
                this.showPopUpCheckout(true);
                // $(".ol-addcart#cart").fadeIn().delay(2000).fadeOut();
                this.synchingCart();

                this.cartFbPixel({
                    "browser": this.$browserDetect.meta.name+ " version: " +this.$browserDetect.meta.version,
                    "sku": this.getSelectedFinalVariant.sku_item,
                    "price": this.getSelectedFinalVariant.valid_price,
                    "product": this.getSelectedFinalVariant.name_short,
                    "variant": this.getSelectedFinalVariant.id,
                    "type":"ATC"
                });

                fbq('track', 'AddToCart', {
                    content_ids: this.product.sku_parent, 
                    content_name: this.product.name,
                    content_type:"product",
                    contents:[{'id': this.getSelectedFinalVariant.sku_item, 'quantity': 1}],
                    currency:"IDR",
                    value:this.getSelectedFinalVariant.valid_price
                });

                this.$gtag.event('add_to_cart', { 'items': [
                    {
                    "id": this.getSelectedFinalVariant.sku_item,
                    "name": this.getSelectedFinalVariant.name_short,
                    "list_name": "Search Results",
                    "brand": this.product.brand,
                    "category": "Furniture",
                    "variant": this.getSelectedFinalVariant.variant,
                    "list_position": 1,
                    "quantity": 1,
                    "price": this.getSelectedFinalVariant.valid_price
                    }
                ] });
            }
            if(!result.success) $(".ol-alert#outOfStock").stop().slideDown().delay(3000).slideUp();
        },
        async toCartCombo(productCombo) {
            if(this.listComboVariantPrimary.length ==  1){
                var searchQuery1=this.selectedComboPrimary; 
                var searchQuery2=this.selectedComboSecondary; 
                productCombo = this.product.r_uni_product_combo.filter(function(item) {
                    return item.v1_name === searchQuery1 && item.v2_name === searchQuery2;
                });
                productCombo = productCombo[0];
                
            }
            

            const _variant = {...productCombo.r_uni_product_variants_main, r_uni_product_get_combo: productCombo, is_get_combo: 1, id_combo: productCombo.id};
            
            this.loading.tocart = true;

            this.$store.commit('transaction/cart/plus2CartFromCombo', _variant); //Tambah Ke Store
            let result = await this.checkStock(_variant);
            this.loading.tocart = false;
            if(result.success){
                this.showPopUpCheckout(true);
                this.synchingCart();

                this.cartFbPixel({
                    "browser": this.$browserDetect.meta.name+ " version: " +this.$browserDetect.meta.version,
                    "sku": productCombo.r_uni_product_variants_main.sku_item,
                    "price": productCombo.r_uni_product_variants_main.valid_price,
                    "product": productCombo.r_uni_product_variants_main.name_short,
                    "variant": productCombo.r_uni_product_variants_main.id,
                    "type":"COMBO"
                });

                fbq('track', 'AddToCart', {
                    content_ids: this.product.sku_parent, 
                    content_name: this.product.name,
                    content_type:"product",
                    contents:[{'id': productCombo.r_uni_product_variants_main.sku_item, 'quantity': 1}],
                    currency:"IDR",
                    value:productCombo.r_uni_product_variants_main.valid_price
                });

                this.$gtag.event('add_to_cart', { 'items': [
                    {
                    "id": productCombo.r_uni_product_variants_main.sku_item,
                    "name": productCombo.r_uni_product_variants_main.name_short,
                    "list_name": "Search Results",
                    "brand": this.product.brand,
                    "category": "Furniture",
                    "variant": productCombo.r_uni_product_variants_main.variant,
                    "list_position": 1,
                    "quantity": 1,
                    "price": productCombo.r_uni_product_variants_main.valid_price
                    }
                ] });
            }
            if(!result.success) $(".ol-alert#outOfStockCombo").stop().slideDown().delay(3000).slideUp();
        },
        async beliLangsung() {
            if(!this.authenticated) {
                this.$router.push({ name: 'more-login', query:{ redirect: this.$route.fullPath }});
                return;
            }

            this.loading.buy = true;
            const _variant = {...this.getSelectedFinalVariant, is_get_combo: 0, r_uni_product_get_combo:null, id_combo:null, quantity: this.quantity};

            if(!this.getSelectedFinalVariant){
                $(".ol-alert#wrongVar").stop().slideDown("fast").delay(3000).slideUp("fast");
                this.loading.buy = false;
                return false;
            }
            
            this.$store.commit('transaction/cart/beliLangsung', _variant); //Tambah Ke Store
            let result = await this.checkStock(_variant);
            this.loading.buy = false;

            if(result.success) {
                await this.synchingCart(false);
                this.cartFbPixel({
                    "browser": this.$browserDetect.meta.name+ " version: " +this.$browserDetect.meta.version,
                    "sku": this.getSelectedFinalVariant.sku_item,
                    "price": this.getSelectedFinalVariant.valid_price,
                    "product": this.getSelectedFinalVariant.name_short,
                    "variant": this.getSelectedFinalVariant.id,
                    "type":"BUY"
                });

                fbq('track', 'AddToCart', {
                    content_ids: this.product.sku_parent, 
                    content_name: this.product.name,
                    content_type:"product",
                    contents:[{'id': this.getSelectedFinalVariant.sku_item, 'quantity': 1}],
                    currency:"IDR",
                    value:this.getSelectedFinalVariant.valid_price
                });

                this.$gtag.event('add_to_cart', {'items': [{
                    "id": this.getSelectedFinalVariant.sku_item,
                    "name": this.getSelectedFinalVariant.name_short,
                    "list_name": "Search Results",
                    "brand": this.product.brand,
                    "category": "Furniture",
                    "variant": this.getSelectedFinalVariant.variant,
                    "list_position": 1,
                    "quantity": 1,
                    "price": this.getSelectedFinalVariant.valid_price
                }]});
                this.$router.push('/buy');
            }
            else {
                $(".ol-alert#outOfStock").stop().slideDown().delay(3000).slideUp();
            }
        },
        async synchingCart(usingQueue = true){
			if(this.authenticated){
				if(this.queue != null) clearTimeout(this.queue);
				if(usingQueue){
					this.queue = setTimeout(async () => {
						await this.save();
					}, 250);
				}else{
					await this.save();
				}
			}
		},
        async toWishlist(e){
            let $store = this.$store;
            let $el = document.getElementById("btn-wishlist");
            this.loading.wishlist = true;
            if(this.authenticated) {
                var formdata = new FormData();
                
                formdata.append("product_id", this.product.id);
                await globalUrl.WS_TRANSACTION.WISHLIST.SAVE(formdata).then(response => {
                    // this.analytics('AddToWishlist');
                    fbq('track', 'AddToWishlist', {
                        content_ids: this.product.id, 
                        content_name: this.product.name,
                        content_type:"product",
                        content_category:"Page Product",
                        contents:[{'id': this.product.sku_parent, 'quantity': 1}],
                        currency:"IDR"
                    });
                    // $(".ol-addcart#wishlist").fadeIn().delay(2000).fadeOut();
                }).catch(error => {
                    
                });
            }
            else {
                await $store.dispatch('profile/wishlist/pushDataGuest', this.product);
                // this.$router.push({ name: 'more-login', query:{ redirect: this.$route.fullPath }});
            }
            this.loading.wishlist = false;
            // ?: toggle class fav
            $el.classList.toggle('fav');
        },
        async chatDelivery(){
            let texts = "Hi More Furniture! Bagi info harga produk dan ongkos kirim  "+this.product.name;
            let sendWa ={
                phone:process.env.VUE_APP_WA_CS,
                text:texts
            }
            window.open('https://api.whatsapp.com/send?'+$.param(sendWa), '_blank');
        },
        async chatAssembly(){
            let texts = "Hi More Furniture! Bagi info biaya rakit produk "+this.product.name;
            let sendWa ={
                phone:process.env.VUE_APP_WA_CS,
                text:texts
            }
            window.open('https://api.whatsapp.com/send?'+$.param(sendWa), '_blank');
        },
        jqScrolling2top(){
            //$("a#a-footer-to-top").trigger("click");
            $("html, body").animate({ scrollTop: 0 }, "slow"); return false;
        },
        jqUnslick(){
            if($('.ol-product-content-detail__images-list-single').hasClass("slick-initialized")) $('.ol-product-content-detail__images-list-single').slick('unslick');
            if($('.ol-product-content-detail__images-list-group').hasClass("slick-initialized")) $('.ol-product-content-detail__images-list-group').slick('unslick');      
        },
        jqSlick(){
            
            if(!this.loading.detail){
                setTimeout(() => {
                    $(".ol-product-content-detail__images-list-single").slick({
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: !1,
                        fade: !1,
                        asNavFor: ".ol-product-content-detail__images-list-group"
                    });

                    $(".ol-product-content-detail__images-list-group").slick({
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        asNavFor: ".ol-product-content-detail__images-list-single",
                        dots: !1,
                        arrows: !0,
                        centerMode: !0,
                        focusOnSelect: !0,
                        centerPadding: "0",
                        responsive: [{
                            breakpoint: 891,
                            settings: {
                                slidesToShow: 3,
                            },
                        }, {
                            breakpoint: 550,
                            settings: {
                                slidesToShow: 3,
                            },
                        }],
                    });
                }, 100);
            }
        },
        jqInspirationUnslick(){
            if($('.ol-related-inspo-list').hasClass("slick-initialized")) $('.ol-related-inspo-list').slick('unslick');
        },
        jqInspirationSlick(){
            if(!this.loading.inspiration){
                setTimeout(() => {
                    $(".ol-related-inspo-list").slick({
                        slidesToScroll: 1,
                        slidesToShow: 1,
                        dots: !1,
                        autoplay: !0,
                        autoplaySpeed: 5e3,
                        arrows: !0,
                        pauseOnFocus: !1,
                        infinite: !0,
                        speed: 800,
                        fade: !1,
                        centerMode: true,
                        variableWidth: true,
                        useTransform: true,
                        cssEase:  'cubic-bezier(0.380, -0.100, 0.390, 1.235)', 
                    })
                }, 100);
            }
        },
        raftProduct(){
            Popup.methods.show("rakit");
        },
		copyLinkWa() {
			
			var dummy = document.createElement('input'),
			// text = window.location.href;
            text = "https://link.morefurniture.id/product/"+this.product.slug;
			document.body.appendChild(dummy);
			dummy.value = text;
			dummy.select();
			document.execCommand('copy');
			document.body.removeChild(dummy);
			$('#copy_url').prop('hidden',false);
			setTimeout(() => { $('#copy_url').prop('hidden',true); }, 1500);
    	},
        fb_share(e,o){
            return window.open("http://www.facebook.com/sharer.php?u="+encodeURIComponent(o)+"&t="+encodeURIComponent(e),"sharer","toolbar=0,status=0,width=626,height=436"),!1
        },
        currentUrl(){
            return 'https://morefurniture.id'+this.$route.fullPath;
            // return window.location.origin+this.$route.fullPath;
        },
        changeTab(type){
            if(type == 1){
                $("div#reviewInWrap").hide();
                $("div#informationInWrap").hide();
                $("div#deskripsiInwrap").show();
                $("a#deskripsi-btn-tab").css({"border-bottom":"3px solid #364B7B","font-weight":"bold","color":"#364B7B"});
                $("a#ulasan-btn-tab").css({"border-bottom":"1px solid #797979","font-weight":"normal","color":"#797979"});
                $("a#informasi-btn-tab").css({"border-bottom":"1px solid #797979","font-weight":"normal","color":"#797979"});
            }else if(type == 2){
                $("div#deskripsiInwrap").hide();
                $("div#informationInWrap").hide();
                $("div#reviewInWrap").show();
                $("a#ulasan-btn-tab").css({"border-bottom":"3px solid #364B7B","font-weight":"bold","color":"#364B7B"});
                $("a#deskripsi-btn-tab").css({"border-bottom":"1px solid #797979","font-weight":"normal","color":"#797979"});
                $("a#informasi-btn-tab").css({"border-bottom":"1px solid #797979","font-weight":"normal","color":"#797979"});
            }else if(type == 3){
                $("div#informationInWrap").show();
                $("div#deskripsiInwrap").hide();
                $("div#reviewInWrap").hide();
                $("a#informasi-btn-tab").css({"border-bottom":"3px solid #364B7B","font-weight":"bold","color":"#364B7B"});
                $("a#ulasan-btn-tab").css({"border-bottom":"1px solid #797979","font-weight":"normal","color":"#797979"});
                $("a#deskripsi-btn-tab").css({"border-bottom":"1px solid #797979","font-weight":"normal","color":"#797979"});
            }
        },
        showPopup(show=true){  
    	    if(this.location) $("select#cities").append(new Option(this.location.city_name, this.location.city_id, true, true)).trigger('change');
            (show)? Popup.methods.show():Popup.methods.hide();
        },
        showPopUpCheckout(show=true){
            (show)? CartPopup.methods.show("checkout"):CartPopup.methods.hide("checkout");
            this.showingPopUpCheckout = show;
        },
        showPopup2(show=true){ 
            if(this.authenticated){
                (show)? Popup.methods.show("pesanan"):Popup.methods.hide("pesanan");
            }else{
                this.$router.push({ name: 'more-login', query:{ redirect: this.$route.fullPath }})
            } 
        },
        saveLocation(){
            this.$store.commit("auth/setLocation", _.find(this.tempLocations,{city_id:$("select#cities").val()}));
            this.$store.dispatch("auth/saveLocation", _.find(this.tempLocations,{city_id:$("select#cities").val()}));
            this.showPopup(false);
        },
        async getRelated(){
            this.loading.related = true;
            await this.fetchRelated(_.map(this.product.r_uni_product_categories,'uni_categories_id'));
            this.loading.related = false;
        },
        async getPartner(){
            this.loading.partner = true;
            await this.fetchPartner(this.product.id);
            this.loading.partner = false;
        },
        async getVoucher(){
            this.loading.voucher = true;
            await this.fetchVoucher(this.product.id);
            this.loading.voucher = false;
            $(".ol-checkout-couponcarousel-list").slick({
                slidesToScroll: 1,
                slidesToShow: 3,
                dots: !1,
                autoplay: !0,
                autoplaySpeed: 5e3,
                arrows: false,
                pauseOnFocus: !1,
                infinite: !0,
                speed: 500,
                fade: !1,
                responsive: [{
                    breakpoint: 891,
                    settings: {
                        slidesToShow: 3
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1
                    }
                }]
            })
        },
        async getLastSeen(){
            this.loading.lastSeen = true;
            if(this.authenticated) await this.saveProductHistory();
            await this.fetchLastSeen();
            this.loading.lastSeen = false;
        },
        async getInspiration(){
            this.loading.inspiration = true;
            this.jqInspirationUnslick()
            await this.fetchRelatedInspiration(this.product.id);
            this.loading.inspiration = false;
            this.jqInspirationSlick();
        },
        async init(){
            await this.fetchReviews();
            var code_link = this.$route.query.utm_content; //Jika tidak ketemu undefined
            var view_link = this.$route.query.view; //Jika tidak ketemu undefined
            var utm_source = this.$route.query.utm_source; //Jika cek utm

            
            this.loading.detail = true;
            this.loading.related = true;
            // this.loading.partner = true;
            this.loading.voucher = true;
            // this.loading.inspiration = true;

            await this.fetchProduct(this.$route.params.slug);
            await this.fetchDataWishlist();
            document.title = this.product.meta_title ? this.product.meta_title :this.product.name;
            this.loading.detail = false;
            if(!this.$route.hash) this.jqScrolling2top();
            this.jqUnslick();

            fbq('track', 'ViewContent', {
                content_category:"Page Product",
                content_type:"product",content_ids: this.product.sku_parent, 
                content_name: this.product.name,
                currency:"IDR",
                value:this.product.price_minimum
            });
            // this.analytics('ViewContent',{"content_name": this.product.name});

            this.jqSlick();

            await Promise.all([
                this.getRelated(),
                // this.getPartner(),
                this.getVoucher(),
                // this.getInspiration(),
                this.getLastSeen()
            ]);
            new WOW().init();
			

            $('.div-stok').css('display','none');

            if(utm_source =='retail'){
                
                $('#outOfStock').css('display','none');
            }

            // if(utm_source !='retail'){
                $('.ol-product-content-detail__action').css('display','block');
            // }

            
        },
        copyNumber(value) {
            navigator.clipboard.writeText(value).then(function () {
                $('#copy_voucher_url').prop('hidden',false);
                setTimeout(() => { $('#copy_voucher_url').prop('hidden',true); }, 1500);
            }, function () {
                // alert('Failure to copy. Check permissions for clipboard')
            });
        },
        youtubeThumb(id, res = 'default') {
            return `https://i.ytimg.com/vi/${id}/${res}.jpg`
        },
        showPopUpVideo(video) {
            this.selectedVideoCatalog.id = video.id;
            this.selectedVideoCatalog.url = video.youtube_url;
            Popup.methods.show("youtubeCatalog");
        },
        toViewCart() {
            this.showPopUpCheckout(false);
            this.$router.push("/cart");
        },
        scrollToHash(hash) {
            this.$nextTick(function () {
                hash = hash.replace('#', '');
                const h = document.getElementsByTagName('header')[0].offsetHeight;
                const el = document.getElementById(hash);
                if(el) {
                    const top = el.getBoundingClientRect().top + window.pageYOffset - h;
                    window.scrollTo({top: top, behavior: 'smooth'});
                }
            });
        },
        groupBy(data, key) {
            return data.reduce((result, currentValue) => {
                // mendapatkan nilai kunci untuk grup
                const groupKey = currentValue[key];
                
                // membuat grup jika belum ada
                if (!result[groupKey]) {
                result[groupKey] = [];
                }
                
                // menambahkan nilai ke grup
                result[groupKey].push(currentValue);
                
                return result;
            }, {});
        },
        qtyPlus(){
            this.quantity++;
            // let qty = $("").val();
            // let val = parseInt($input.val());
            // $input.val( val+1 ).change();
        },
        qtyMinus(){
            var qty = this.quantity;
            if(qty == 1){
                this.quantity = 1;
            }else{
                this.quantity = qty-1;
            }
        },
        resetData() {
            this.quantity = 1;
        },
        async checkShipping() {
            
            if (!this.getSelectedFinalVariant) {
                $(".ol-alert#wrongVar").stop().slideDown("fast").delay(3000).slideUp("fast");
                $('#cities').val(null);
                return false;
            }
            var city_id = $("#cities").val();
            
            const data = {
                city: city_id,
                sku_group: this.getSelectedFinalVariant.sku_group,
                sku_item: this.getSelectedFinalVariant.sku_item,
                quantity: 1
            };

            try {
                const response = await PRODUCT.SHIPPING_PRICE(data);
                
                if(response.data.data.isValid == false){
                    $("#shipping-price").html(response.data.data.message);
                }else{
                    $("#shipping-price").html(response.data.data.price_rp);
                }
                
            } catch (error) {
                console.error(error);
            }
        }
    
    },
    
    beforeRouteUpdate(to, from, next) {
        // Panggil fungsi resetData sebelum meninggalkan halaman
        this.resetData();
        next();
    },
    async mounted(){
        

        if(this.$route.query?.view_link) {
            this.addViewLink(this.$route.query.view_link);
        }
        
        await this.init();
        ReviewList.methods.toPage(this.product.id,1);

        $('#show-more-content').hide();

        $('#show-more').click(function(){
            $('#show-more-content').show(300);
            $('#show-less').show();
            $('#show-more').hide();
        });

        $('#show-less').click(function(){
            $('#show-more-content').hide(150);
            $('#show-more').show();
            $(this).hide();
        });

        this.jsonld = {
            "@context": "https://schema.org/",
            "@type": "Product",
            "name": this.product.name,
            "image": [
                this.product.r_uni_product_images[0].image_url
            ],
            "description": this.product.meta_desc,
            "sku": this.product.sku_parent,
            "brand": {
                "@type": "Brand",
                "name": this.product.brand
            },
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": this.product.seo_2,
                "reviewCount": this.product.seo_3
            },
            "offers": {
                "@type": "Offer",
                "url":window.location.origin+this.$route.fullPath,
                "priceCurrency": "IDR",
                "price": this.product.price_minimum,
                "itemCondition": "https://schema.org/UsedCondition",
                "availability": "https://schema.org/InStock"
            }
        };
        if(this.$route.hash) {
            this.scrollToHash(this.$route.hash);
        }

        this.quantity = 1;

        $('#cities').select2().on('change', (e) => {
            this.checkShipping();
        });

        // select2.ajax("select#cities",BASE+UNI._CITY_ALL,'city_id','city_name',(response)=>(this.tempLocations = response));
        await this.getCitiesOverallJson();
    },
    watch:{
        $route(to, from) {
            this.init();
            this.showPopUpCheckout(false);
            if(this.$route.hash) {
                this.scrollToHash(this.$route.hash);
            }
        },
        "getSelectedFinalVariant":function(value){
            this.$store.commit("product/detail/setProductFlash", null);
            this.$store.commit("product/detail/setProductFree", null);
            this.$store.commit("product/detail/setProductPreOrder", null);

            if(value.r_uni_product_flashsale){
                this.$store.commit("product/detail/setProductFlash", value.r_uni_product_flashsale);
                this.getFlashSale().then((data)=>{
                    if(data && !this.stopCountdown){
                        this.isFlashsale =  true;
                        this.countdown = this.flashSaleAktif.start > moment().unix() ? this.flashSaleAktif.start - moment().unix():this.flashSaleAktif.end - moment().unix();
                        this.stopCountdown = setInterval(() => {
                        this.countdown -= 1
                        if (!this.countdown) {
                            clearInterval(this.stopCountdown)
                            window.location.reload();
                        }
                        }, 1000)
                    }
                });
            }
            if(value.r_uni_product_get_free) this.$store.commit("product/detail/setProductFree", value.r_uni_product_get_free);
            if(value.r_uni_product_get_po) this.$store.commit("product/detail/setProductPreOrder", value.r_uni_product_get_po);
            
        },
    }
};
</script>
<style scoped lang="css">
    @import '../../assets/css/bootstrap.css';
    @import '../../assets/css/productdetailnew.css';
    
    @import '../../assets/css/coupon.css';
    @import '../../assets/css/88.css';

    .ol-product-content-detail__action{
        margin-top:16px
    }
    @media(max-width:767px){
        .ol-product-content-detail__action{
            /* display:none; */
            display:block;
            margin: 0px;
            position: fixed;
            bottom: 0;
            z-index: 999;
            width: 100%;
            background-color: white;
            left: 0;
            padding: 8px;
            text-align: center;
            margin-top:0px
        }

        .ol-btn-wishlist{
            margin-top:  0px;
        }

        #button_cart{
            /* width: 40%; */
            width: 75%;
            margin-right: 0px;
        }
    }
    
    .ol-btn-disable{
        background-color: #aaa;
        border-color: #aaa;
        cursor: default;
        pointer-events: none;
    }
    .countdownFlash{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 10px; text-align: center;
        gap: 5px;
        background-color: #364B7B;
    }

    .countdownFlash small{
        color: rgb(173, 173, 173);
        display: none;

    }

    .pre-order{
        padding:8px 16px 8px 16px;
        margin-bottom: 16px;
        border-radius: 8px;
        background:#4E6CB1;
        border: 1px solid #364B7B;
        color: #FFFFFF;
    }

    .morepromise{
        padding:8px 16px 8px 16px;
        margin-bottom: 16px;
        border-radius: 8px;
        background: linear-gradient(270.05deg, #A537C1 44.89%, #722785 76.49%);
        color: #fff;
    }

    .morepromise span{
        color: #000000 ;
    }

    .morepromise a{
        
        /* font-weight: bold; */
        color:#FFF;
        /* text-decoration: underline; */
        /* animation: blink 2s linear infinite; */
    }
    @keyframes blink{
        0%{opacity: 0;}
        100%{opacity: 1;}
    }

    .disable-stock{
        border: 1px solid #A7B0BE;
        background-color: #F6F7F9;
        color: #A7B0BE;
    }
    .item-detail-images-youtube{
        position: relative;
    }
    .ol-product-img-container.youtube{
        cursor: pointer;
    }
    .play-img{
        position: absolute;
        top: 25%;
        left: 25%;
        width: 50%;
        height: 50%;
        text-align: center;
    }
    .play-img-single{
        position: absolute;
        top: 36%;
        left: 38%;
        width: 25%;
        height: 25%;
        text-align: center;
    }
    .iframe-youtube-catalog{
        width: 100%;
        height: 500px;
    }
    /* .morepromise a:hover{
        font-weight: bolder;
        color: #354B7A ;
    } */

    .read-more-state {
  display: none;
}

.read-more-target {
  opacity: 0;
  max-height: 0;
  font-size: 0;
  transition: .25s ease;
}

.read-more-target img {
  height: 0;
}

.read-more-state:checked ~ .read-more-wrap .read-more-target img {
  height: auto;
}

.read-more-state:checked ~ .read-more-wrap .read-more-target {
  opacity: 1;
  font-size: inherit;
  max-height: 999em;
}

.read-more-state ~ .read-more-trigger:before {
  content: 'Tampilkan Lebih Banyak';
}

.read-more-state:checked ~ .read-more-trigger:before {
  content: 'Tampilkan Lebih Sedikit';
}

.read-more-trigger {
  cursor: pointer;
  margin-bottom:8px;
  margin-top:8px;
}


.read-combo-state {
    display: none;
  }
  
  .read-combo-target {
    opacity: 0;
    max-height: 0;
    font-size: 0;
    transition: .25s ease;
  }
  
  .read-combo-target img {
    height: 0;
  }
  
  .read-combo-state:checked ~ .read-combo-wrap .read-combo-target img {
    height: auto;
  }
  
  .read-combo-state:checked ~ .read-combo-wrap .read-combo-target {
    opacity: 1;
    font-size: inherit;
    max-height: 999em;
  }
  
  .read-combo-state ~ .read-combo-trigger:before {
    content: 'Tampilkan Lebih Banyak';
  }
  
  .read-combo-state:checked ~ .read-combo-trigger:before {
    content: 'Tampilkan Lebih Sedikit';
  }
  
  .read-combo-trigger {
    cursor: pointer;
    margin-bottom:8px;
    margin-top:8px;
    position: relative;
  }
  

.rakit-info{
    margin-bottom: 8px;display:flex;display: flex;align-items: center;
}

.rakit-info-label{
    width:3%;
}

.rakit-info-description{
    width:97%;
}
@media(max-width:767px){
    .rakit-info-label{
        width:8%;
    }

    .rakit-info-description{
        width:92%;
    }
}

/* Start Retail */
.retail-dekstop{
    display:block;
}

.retail-mobile{
    display:none;
}

@media(max-width:767px){
    .retail-dekstop{
        display:none;
    }

    .retail-mobile{
        display:block;
    }
}

.qty {
    width: 40px;
    height: 25px;
    text-align: center;
}
input.qtyplus { width:25px; height:25px;}
input.qtyminus { width:25px; height:25px;}
</style>
