<template>
  <BaseLayout>
    <div class="ol-addcart" id="copy_voucher_url" hidden="true">
      <i class=""></i> Kode Voucher Berhasil tersimpan
    </div>
    <section class="ol-promotion-info" id="header-promotion">
      <div class="ol-wrapper ol-wrapper--large">
        <div class="ol-promotion-info__list add-fix">
          <div class="ol-promotion-info__list-item" >
            <div class="ol-promotion-info__list-item-wrapper">
              <a @click.prevent="scrollToHash('voucher')" href="#voucher" class="scrollLink">
                <div class="row no-gutters">
                  <div class="col-md-12 col-lg-2">
                    <img :src="require('@/assets/images/promotion/voucher-blue.png')">
                  </div>
                  <div class="col-md-12 col-lg-10 ol-promotion-label">
                    <span>Voucher</span>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div class="ol-promotion-info__list-item" >
            <div class="ol-promotion-info__list-item-wrapper">
              <a @click.prevent="scrollToHash('flashsale')" href="#flashsale" class="scrollLink">
                <div class="row no-gutters">
                  <div class="col-md-12 col-lg-2">
                    <img :src="require('@/assets/images/promotion/flash-sale-blue.png')">
                  </div>
                  <div class="col-md-12 col-lg-10 ol-promotion-label">
                    <span>Flash Sale</span>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div class="ol-promotion-info__list-item" >
            <div class="ol-promotion-info__list-item-wrapper">
              <a @click.prevent="scrollToHash('gift')" href="#gift" class="scrollLink">
                <div class="row no-gutters">
                    <div class="col-md-12 col-lg-2">
                      <img :src="require('@/assets/images/promotion/free-produk-blue.png')">
                    </div>
                    <div class="col-md-12 col-lg-10 ol-promotion-label">
                      <span>Gratis Hadiah</span>
                    </div>
                </div>
              </a>
            </div>
          </div>
          <!-- <div class="ol-promotion-info__list-item" >
            <div class="ol-promotion-info__list-item-wrapper">
              <a @click.prevent="scrollToHash('paket')" href="#combo" class="scrollLink">
                <div class="row no-gutters">
                  <div class="col-md-12 col-lg-2">
                    <img :src="require('@/assets/images/promotion/combo-hemat-blue.png')">
                  </div>
                  <div class="col-md-12 col-lg-10 ol-promotion-label">
                    <span>Paket Hemat</span>
                  </div>
                </div>
              </a>
            </div>
          </div> -->
          <div class="ol-promotion-info__list-item" >
            <div class="ol-promotion-info__list-item-wrapper">
              <a @click.prevent="scrollToHash('combo')" href="#combo" class="scrollLink">
                <div class="row no-gutters">
                  <div class="col-md-12 col-lg-2">
                    <img :src="require('@/assets/images/promotion/combo-hemat-blue.png')">
                  </div>
                  <div class="col-md-12 col-lg-10 ol-promotion-label">
                    <span>Kombo Hemat</span>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="ol-home-menu">
      <BannerBottomLoading v-if="loading" />
      <BannerTop v-else :banners="bannerTop" />
    </section>
    <div class="break-24"></div>
    <div class="ol-wrapper ol-wrapper--large">
      <div v-if="reedem.length > 0" class="ol-product-content ol-product-related add-fix"  id="tebus">
        <h2 class="ol-section-title">
          <span>Promo Tebus Murah</span>
          <i></i>
        </h2>
        <ul v-if="reedem.length > 0" class="card-reedems" style="margin-bottom:16px;">
          <li class="card-reedem" v-for="reedem in reedem" :key="'reedem-'+reedem.id">
            <div class="row no-gutters">
              <div class="col-4" style="padding:4px;">
                <img :src="reedem.r_uni_product_variants_main.r_uni_product_image.image_url" width="100%">
              </div>
              <div class="col-8">
                <div class="card-reedem-min-price">Min. Pembelian {{ reedem.min_price_rp }}</div>
                <h3 class="ol-home-product__content-item-list--item-title">
                  {{ reedem.r_uni_product_variants_main.name_long }}
                </h3>
                <div class="flex-card-product">
                  <div class="price-discount-card-product">{{ reedem.price_normal_reedem_rp }}</div>
                  <div class="box-discount-card-product">
                    <div class="discount-card-product">{{ reedem.discount_reedem }}</div>
                  </div>
                </div>
                <div class="row no-gutters">
                  <div class="col-12 price-card-product">
                    {{ reedem.price_reedem_rp }}
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="break-24"></div>
    <section id="voucher">
      <div class="ol-wrapper ol-wrapper--large">
        <h2 class="ol-section-title">
          <img :src="require('@/assets/images/promotion/voucher-black.png')" width="17" style="margin-right:8px"><span>Voucher</span>
          <i></i>
        </h2>
        <ul v-if="voucher.length > 0" class="card-vouchers">
            <li class="card-voucher" v-for="voucher in voucher" :key="'voucher-'+voucher.id">
                <div class="main">
                    <div class="content-voucher">
                        <h2 class="discount-container">
                            <img class="discount-img" :src="require('@/assets/images/discount.png')" width="20">
                            Diskon &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span v-if="voucher.show_on === 1">{{ voucher.voucher_rp }}</span>
                            <span v-else-if="voucher.show_on === 3">{{ voucher.max_discount_price_rp }}</span>
                            <span v-else-if="voucher.show_on === 4">{{ voucher.percentage }}%</span>
                        </h2>
                        <div class="break-1"></div>
                        <p v-if="voucher.min_purchase_rp != 'Rp0'">Min. Transaksi <b>{{ voucher.min_purchase_rp }}</b></p>
                        <p v-else>Tanpa Min. Transaksi</p>
                        <p>Hingga <b>{{ voucher.expired }}</b></p>
                    </div>
                </div>
                <div class="copy-button">
                    <button @click="copyNumber(voucher.code)" class="copybtn">Salin</button>
                </div>
            </li>
        </ul>
      </div>
    </section>
    <div class="break-24"></div>
    <FlashsaleGroup id="flashsale" />
    <div class="break-24"></div>
    <section class="ol-home-type-product" id="gift">
      <div class="ol-wrapper ol-wrapper--large">
        <div class="row no-gutters">
          <div class="col-6">
            <h2 class="ol-section-title">
              <img :src="require('@/assets/images/promotion/free-produk-black.png')" width="17" style="margin-right:8px"><span>Gratis Hadiah</span>
              <i></i>
            </h2>
          </div>
          <div class="col-6" style="text-align:right;">
            <div style="margin-top:5px;">
              <a href="/products/q=gratis" style="color:#C13B37"><b>Lihat Semua</b> <i class="fa fa-arrow-right"></i></a>
            </div>
          </div>
        </div>
        
        <div class="ol-product-content ol-product-related">
          <ProductLoader wrapclass="ol-product-content__center-list" :page="2" :qty="5" v-if="loadingFrees"/>
          <ProductListFree :products="frees" v-if="!loadingFrees" carouselKey="productFrees" />
        </div>
      </div>
    </section>
    <!-- <section class="ol-home-type-product" id="recomendation">
      <ProductListGroup :groups="productGroups" />
    </section> -->
    
    <div class="break-24"></div>
    <section class="ol-home-type-product" id="combo">
      <div class="ol-wrapper ol-wrapper--large">
        <div class="row no-gutters">
          <div class="col-6">
            <h2 class="ol-section-title">
              <img :src="require('@/assets/images/promotion/combo-hemat-black.png')" width="17" style="margin-right:8px"><span>Kombo Hemat</span>
              <i></i>
            </h2>
          </div>
          <div class="col-6" style="text-align:right;">
            <div style="margin-top:5px;">
              <a href="/products/q=kombo" style="color:#C13B37"><b>Lihat Semua</b> <i class="fa fa-arrow-right"></i></a>
            </div>
          </div>
        </div>
        <div class="ol-product-content ol-product-related">
          <ProductLoader wrapclass="ol-product-content__center-list" :page="2" :qty="5" v-if="loadingCombos"/>
          <CarouselProductCombo :products="combos" v-if="!loadingCombos" carouselKey="combos" />
        </div>
      </div>
    </section>
    
  </BaseLayout>
</template>

<script>
import BaseLayout from '@/components/landing/BaseLayout';
import BannerBottomLoading from '@/components/loading/BannerBottomLoading';
import BannerTop from '@/components/home/BannerTop';
import ProductLoader from '@/components/loading/product/Content';
import CarouselProduct from '@/components/home/CarouselProduct';
import CarouselProductFree from '@/components/home/CarouselProductFree';
import CarouselProductCombo from '@/components/home/CarouselProductCombo';
import ProductListFree from "@/components/product/ProductListFree.vue"
import FlashsaleGroup from '@/components/product/FlashsaleGroup';
import ProductListGroup from '@/components/product/ProductListGroup';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'PromotionActive',
  metaInfo() {
    return {
      link: [
        {rel: 'canonical', href: window.location.origin+this.$route.fullPath}
      ]
    }
  },
  components: {
    BaseLayout,
    BannerBottomLoading,
    BannerTop,
    ProductLoader,
    CarouselProduct,
    CarouselProductFree,
    FlashsaleGroup,
    ProductListGroup,
    CarouselProductCombo,
    ProductListFree,
  },
  data() {
    return {
      loading:false,
      loadingBottom:false,
      loadingLastPaket:false,
      loadingFrees:false,
      loadingCombos:false,
      loadingPakets:false,
      loadingLastPaket:true,
    }
  },
  computed: {
    ...mapGetters('product/list', ['products', 'topProducts']),
    ...mapGetters('home', ['bannerTop', 'productGroups']),
    ...mapGetters('auth', ['authenticated']),
    ...mapGetters('promotion', ['frees', 'combos','pakets']),
    ...mapGetters("product/detail", ['voucher']),
    ...mapGetters('profile/product', ['lastSeen','lastPaket']),
    ...mapGetters("transaction/cart", ["reedem","checkReedem","checkMinReedem"]),
  },
  methods: {
    ...mapActions("transaction/cart", ["fetchReedem"]),
    ...mapActions('profile/product', ['saveProductHistory','fetchLastSeen','fetchLastPaket']),
    ...mapActions('home', ['fetchProductGroups', 'getBanner']),
    ...mapActions('product/list', ['fetchProducts', 'fetchTopProducts']),
    ...mapActions('promotion', ['fetchFrees', 'fetchCombos', 'fetchPakets']),
    ...mapActions("product/detail", ['fetchVoucher']),
    initSlickRecomendation() {
      if($('.ol-home-group-list').hasClass("slick-initialized")) $(".ol-home-group-list").slick('unslick');
      $(".ol-home-group-list").slick({
        slidesToScroll: 1,
        swipeToSlide: true,
        verticalSwiping: false,
        slidesToShow: 5,
        dots: false,
        autoplay: true,
        autoplaySpeed: 5e3,
        arrows: !0,
        pauseOnFocus: !1,
        infinite: false,
        speed: 500,
        fade: !1,
        responsive: [{
          breakpoint: 1500,
          settings: {
            slidesToShow: 5
          }
        }, {
          breakpoint: 1160,
          settings: {
            slidesToShow: 4
          }
        }, {
          breakpoint: 748,
          settings: {
            slidesToShow: 3
          }
        }, {
          breakpoint: 530,
          settings: {
            slidesToShow: 3
          }
        }, {
          breakpoint: 481,
          settings: {
            slidesToShow: 2
          }
        }]
      });
    },
    async getLastSeen(){
        this.loadingLastPaket = true;
        // if(this.authenticated) await this.saveProductHistory();
        await this.fetchLastPaket();
        // await this.fetchPakets();

        this.loadingLastPaket = false;
    },
    scrollToHash(hash) {
      hash = hash.replace('#', '');
      const h = document.getElementsByTagName('header')[0].offsetHeight + document.getElementById('header-promotion').offsetHeight;
      const el = document.getElementById(hash);
      if(el) {
        const top = el.getBoundingClientRect().top + window.pageYOffset - h;
        window.scrollTo({top: top, behavior: 'smooth'});
        this.$router.push({hash: hash});
      }
    },
    copyNumber(value) {
      navigator.clipboard.writeText(value).then(function () {
        $('#copy_voucher_url').prop('hidden',false);
        setTimeout(() => { $('#copy_voucher_url').prop('hidden',true); }, 1500);
      }, function () {
        // alert('Failure to copy. Check permissions for clipboard')
      });
    },
    async getReedem(){
          await this.fetchReedem();
      },
  },
  async created() {
    this.$store.commit('transaction/checkout/setOrder', null);
  },
  updated() {
    // if(this.bannerTop) this.jqSlickTopBanner();
    // if(this.bannerBottom) this.jqSlickBotttomBanner();
  },
  async mounted() {
    this.loading = true;
    this.loadingBottom = true;
    this.loadingFrees = true;
    this.loadingCombos = true;
    this.loadingPakets = true;

    await Promise.all([
      this.getBanner('1'),
      this.getBanner('2'),
      this.fetchVoucher(),
      this.fetchProductGroups(),
      this.fetchFrees(),
      this.fetchCombos(),
    ]);
    await this.getLastSeen();

    this.initSlickRecomendation();

    this.loading = false;
    this.loadingBottom = false;
    this.loadingFrees = false;
    this.loadingCombos = false;
    this.loadingPakets = false;

    if(this.$route.hash) {
      this.scrollToHash(this.$route.hash);
    }

    this.getReedem();
  },
  watch:{
    bannerTop: function(value){
      this.loading = false;
    },
    bannerBottom: function(value){
      this.loadingBottom =false;
    },
  },
}
</script>

<style scoped lang="css">
@import '../../assets/css/coupon.css';
@import '../../assets/css/bootstrap.css';
@import '../../assets/css/reedem.css';

.ol-home-menu{
  padding-top:24px;
}

@media (max-width: 768px) {
  .ol-home-menu{
    padding-top:0px;
  }
}
</style>