<template>
    <div>
        <header class="ol-header-checkout">
            <div class="ol-wrapper ol-wrapper--large">
            <h1 class="ol-header-checkout__logo">
                <router-link to="/" style="color:white">
                <img
                    src="https://asset.morefurniture.id/MORE/asset/logo-more-white.png"
                    alt="MORE"
                    title="MORE" style="vertical-align: middle;"
                /> | Checkout
                </router-link>
            </h1>
            </div>
        </header>
        <section class="ol-section ol-checkout header-checkout">
            <div class="ol-checkout-step">
                <div id="step-Shipping" class="ol-checkout-step__item">
                    <div title="Alamat Pengiriman">
                    <i class="ol-fa fas fa-truck"></i>
                    </div>
                    <span>Alamat Pengiriman</span>
                </div>
                <div id="step-Payment" class="ol-checkout-step__item">
                    <div title="Pilih Pembayaran">
                    <i class="ol-fa far fa-credit-card"></i>
                    </div>
                    <span>Pilih Pembayaran</span>
                </div>
                <div id="step-Review" class="ol-checkout-step__item">
                    <div title="Rincian Belanja">
                    <i class="ol-fa fas fa-clipboard-list"></i>
                    </div>
                    <span>Rincian Belanja</span>
                </div>
            </div>
            <router-view></router-view>

        </section>

        <a href="#" class="ol-totop"><i class="ol-fa fas fa-arrow-up"></i></a>
        <footer class="ol-footer-checkout">
            <div class="ol-wrapper ol-wrapper--small">
            <nav class="ol-footer-checkout__nav">
                <router-link to="/snk" target="_blank">Syarat dan Ketentuan</router-link> |
                <router-link to="/faq" target="_blank">FAQ</router-link>
            </nav>

            <div class="ol-footer-checkout__copyright">
                <img :srcset="assets('morelogo_nd.png')" alt="MORE" title="MORE"> &copy; {{new Date().getFullYear()}}. Hak Cipta Dilindungi
            </div>
            </div>
        </footer>
    </div>
</template>

<script>

import { mapGetters,mapActions } from 'vuex'
export default {
    name: "Checkout",

    methods:{
        
        setStep(){
            if(this.$route.name == "Success"){
                $(".ol-checkout-step,.ol-section-title").hide();
            }else if(this.$route.name == "Information"){
                $(".ol-checkout-step,.ol-section-title").hide();
                $(".ol-footer-checkout").hide();
                $(".ol-totop").hide();
                $(".ol-header-checkout").hide();
            }else{
                $(".ol-checkout-step,.ol-section-title").show();
                $("div.ol-checkout-step__item").removeClass("ol-checkout-step__item--progress");
                $("div#step-"+this.$route.name).addClass("ol-checkout-step__item--progress").prevAll().addClass("ol-checkout-step__item--progress");
            }
        }
    },
    async mounted(){
        this.setStep();
        
    },
    updated(){
        this.setStep();
    }
};
</script>

